import * as React from "react";
import { useForm } from "react-hook-form";
import isEmpty from "lodash/isEmpty";
import { useState, useEffect } from "react";
import { db, storage } from "../firebase";
import { getDocs, collection, addDoc, query, where } from "firebase/firestore";
import { UserAuth } from "../context/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import { showAlert } from "tailwind-toastify";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

export default function ScheduleReportForm({
  formData,
  setFormData,
  facilityLocation,
  serviceName,
  container,
}) {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();
  // eslint-disable-next-line
  const [successMsg, setSuccessMsg] = useState("");
  // eslint-disable-next-line
  const [facility, setFacility] = useState(""); // Assuming 'facility' is a string, adjust the type accordingly
  // eslint-disable-next-line
  const [name, setName] = useState("");

  const { user } = UserAuth();
  const uid = user ? user.uid : null; // Check if user exists before accessing the UID
  const navigate = useNavigate();
  //const { facility } = useContext(FacilityContext);
  //const submitDate = new Date().toLocaleDateString("en-GB");
  const submitDate = new Date().toLocaleString("en-GB");

  //console.log("Facility value:", facility);

  const location = useLocation();

  useEffect(() => {
    // Function to fetch data from AppUsers collection
    const fetchUserData = async () => {
      try {
        const appUsersQuerySnapshot = await query(
          collection(db, "AppUsers"),
          where("userId", "==", uid)
        );
        const appUsersDocs = await getDocs(appUsersQuerySnapshot);

        if (!isEmpty(appUsersDocs.docs)) {
          const userData = appUsersDocs.docs[0].data();
          const { name, facility } = userData;
          console.log("serviceName in useEffect:", serviceName);
          // Set the fetched data in the form using setValue
          // eslint-disable-next-line
          setValue("name", name);
          setValue("serviceName", serviceName);
          // eslint-disable-next-line
          setValue("facility", facility);
          //setValue("service", service);
          setValue("facilityLocation", facilityLocation);
          setValue("container", container);
        } else {
          // Handle the case when no document is found
          console.error("User data not found");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    // Call the function to fetch user data
    if (uid) {
      fetchUserData();
    }
  }, [uid, setValue, facilityLocation, serviceName, container]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [location]);

  const [isOnline, setIsOnline] = useState(true);

  /*   useEffect(() => {
    function handleOffline() {
      console.log("Went offline");
      setIsOnline(false);
    }

    function handleOnline() {
      console.log("Went online");
      setIsOnline(true);
    }

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, []); */

  useEffect(() => {
    function handleOffline() {
      setIsOnline(false);
    }

    function handleOnline() {
      setIsOnline(true);
    }

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, []);

  const [offlineToast, setOfflineToast] = useState(null);
  const [successToast, setSuccessToast] = useState(null);

  const onSubmit = async (formData) => {
    let referenceNumber; // Declare referenceNumber variable

    if (isOnline) {
      // Perform form submission logic for online mode
      console.log("Form submitted online");
      navigate("/");
    } else {
      // Perform form submission logic for offline mode
      console.log("Form submitted offline");
      navigate("/");
    }

    try {
      let fileMetadata = null; // Initialize fileMetadata as null

      if (formData.file && formData.file.length > 0) {
        const file = formData.file[0];
        // Create a reference to the storage location
        const storageRef = ref(storage, `images/schedule/${file.name}`);

        // Upload the file to storage
        await uploadBytes(storageRef, file);

        // Get the file URL
        const fileUrl = await getDownloadURL(storageRef);

        fileMetadata = {
          name: file.name,
          type: file.type,
          size: file.size,
          url: fileUrl,
        };
      }

      referenceNumber = generateReferenceNumber(formData.issue_type);

      await addDoc(collection(db, "Issue"), {
        submitDate: submitDate,
        name: formData.name,
        facility: formData.facility, // Use the form data
        uid: formData.uid, // Use the form data
        issue_message: formData.issue_message,
        issue_type: formData.issue_type,
        issue_status: formData.issue_status,
        referenceNumber: referenceNumber,
        serviceName: formData.serviceName,
        container: formData.container,
        facilityLocation: formData.facilityLocation,
        file: fileMetadata,
        isRead: false, // Set isRead to false by default
      });

      toast.success(
        `We've received your support request and will get back to you ASAP. Reference Number: ${referenceNumber}`,
        {
          duration: 4000, // Duration in milliseconds
        }
      );

      console.log("Form submitted", formData);
      reset();
    } catch (error) {
      console.error("Error uploading file to Firebase Storage:", error);
    }
  };

  // Function to generate a custom reference number based on issue type
  const generateReferenceNumber = (issueType) => {
    // You can customize this logic based on your requirements
    const prefix = issueType === "Schedule" ? "SCH" : "CO";
    const randomDigits = Math.floor(10000 + Math.random() * 90000);
    return `${prefix}${randomDigits}`;
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          {...register("facilityLocation")}
          type="hidden"
          value={facilityLocation}
        />
        <input {...register("uid")} type="hidden" value={uid} name="uid" />
        {/* Add additional hidden fields for data from AppUsers */}
        <input {...register("name")} type="hidden" value="" name="name" />
        <input
          {...register("issue_type")}
          type="hidden"
          value="Schedule"
          name="issue_type"
        />
        <input type="hidden" {...register("issue_status")} value="true" />
        <div className="mt-2">
          {successMsg && <p className="success-msg">{successMsg}</p>}

          <div className="pb-1 pt-1 rounded-lg bg-white">
            {errors.root?.serverError && (
              <p>Something went wrong, and please try again.</p>
            )}

            <h1 className="text-center text-xl font-semibold mb-4">
              Request an issue.
            </h1>

            <p className="text-center text-sm mb-4">
              Please Use this form to report an issue. We'll get back to you
              ASAP.
            </p>
          </div>

          <div className="border-b border-gray-900/10 pb-12 pb-1 pt-1 rounded-lg bg-white px-5 py-6 sm:px-6">
            <div className="space-y-10">
              <fieldset disabled="true">
                <div className="sm:col-span-4">
                  <div className="mt-2">
                    <div className="text-xs flex rounded-md shadow-sm focus-within:ring-2 focus-within:ring-inset focus-within:ring-teal-300 sm:max-w-md">
                      <input
                        {...register("serviceName")}
                        type="text"
                        value={serviceName}
                        className="block flex-1 rounded-lg border-0 bg-slate-100 py-1.5 pl-1 text-slate-600 placeholder:text-white focus:ring-0 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
                <div className="sm:col-span-4">
                  <div className="mt-2">
                    <div className="text-xs flex rounded-md shadow-sm focus-within:ring-2 focus-within:ring-inset focus-within:ring-slate-300 sm:max-w-md">
                      <input
                        {...register("container")}
                        type="text"
                        value={container}
                        className="block flex-1 rounded-lg border-0 bg-slate-100 py-1.5 pl-1 text-slate-600 placeholder:text-white focus:ring-0 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
                <div className="sm:col-span-4">
                  <div className="mt-2">
                    <div className="text-xs flex rounded-md shadow-sm focus-within:ring-2 focus-within:ring-inset focus-within:ring-slate-300 sm:max-w-md">
                      <input
                        {...register("facilityLocation")}
                        type="text"
                        value={facilityLocation}
                        className="block flex-1 rounded-lg border-0 bg-slate-100 py-1.5 pl-1 text-slate-600 placeholder:text-white focus:ring-0 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
              </fieldset>

              <fieldset>
                <legend className="text-sm font-semibold leading-6 text-gray-900"></legend>
                <textarea
                  {...register("issue_message", {
                    required: "Please enter an explanation",
                  })}
                  aria-invalid={errors["issue_message"] ? "true" : "false"}
                  className="w-full p-2 border rounded-md"
                  placeholder="Details of schedule issue"
                ></textarea>
                <legend className="text-sm font-semibold leading-6 text-gray-900">
                  Add an image (optional)
                </legend>
                <input
                  type="file"
                  name="file"
                  id="file"
                  {...register("file")}
                  className="w-full p-2 border rounded-md"
                />
                {errors.issue_message && (
                  <div className="text-red-600">
                    {errors.issue_message.message}
                  </div>
                )}
              </fieldset>
            </div>
          </div>
          <div className="mt-2 pb-1 pt-1 rounded-lg bg-white px-5 py-6 sm:px-6 flex items-center justify-end gap-x-6">
            <button
              disabled={isSubmitting}
              type="submit"
              className="rounded-md bg-sky-100 px-3 py-2 text-sm font-semibold text-sky-700 shadow-sm hover:bg-sky-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

import { PaperAirplaneIcon } from "@heroicons/react/20/solid";
import React, { useState, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  serverTimestamp,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebase";
import { UserAuth } from "../context/AuthContext";
const IssueForm = ({ referenceNumber }) => {
  const { user } = UserAuth();
  const { register, handleSubmit, reset } = useForm();
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [appUserData, setAppUserData] = useState(null);
  const messagesEndRef = useRef(null); // Create a ref for the message display area

  useEffect(() => {
    // Scroll to the bottom of the message display area whenever messages update
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (user) {
          // Fetch appUserData logic here
          // For example:
          const userQuery = query(
            collection(db, "AppUsers"),
            where("userId", "==", user.uid)
          );
          const userSnapshot = await getDocs(userQuery);
          if (!userSnapshot.empty) {
            const userData = userSnapshot.docs[0].data();
            setAppUserData(userData);
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [user]); // Include user in the dependency array

  useEffect(() => {
    setLoading(true);
    const issueQuery = query(
      collection(db, "Issue"),
      where("referenceNumber", "==", referenceNumber)
    );
    const messagesData = {}; // Create a dictionary to store messages
    const unsubscribe = onSnapshot(issueQuery, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const issueDocRef = doc.ref;
        const messageCollectionRef = collection(issueDocRef, "Message");
        const messageUnsubscribe = onSnapshot(
          messageCollectionRef,
          (messageSnapshot) => {
            messageSnapshot.docChanges().forEach((change) => {
              const message = change.doc.data();
              const messageId = change.doc.id;
              if (change.type === "added" || change.type === "modified") {
                messagesData[messageId] = message; // Store or update message in the dictionary
              } else if (change.type === "removed") {
                delete messagesData[messageId]; // Remove message from the dictionary
              }
            });
            setMessages(Object.values(messagesData)); // Set the state with values from the dictionary
          }
        );
      });
      setLoading(false);
    });

    return unsubscribe;
  }, [referenceNumber]);

  const handleFormSubmit = async (data) => {
    try {
      // Query the Issue collection to find the document with the matching referenceNumber
      const issueQuery = query(
        collection(db, "Issue"),
        where("referenceNumber", "==", referenceNumber)
      );
      const issueSnapshot = await getDocs(issueQuery);

      // If a document with the matching referenceNumber is found
      if (!issueSnapshot.empty) {
        const issueDocRef = issueSnapshot.docs[0].ref;

        // Add a new document to the "Message" sub-collection of the found Issue document
        const messageDocRef = await addDoc(collection(issueDocRef, "Message"), {
          message: data.message,
          timestamp: serverTimestamp(),
          utype: true,
        });
        console.log("Message written with ID: ", messageDocRef.id);
        reset();
      } else {
        console.error(
          "No document found with referenceNumber: ",
          referenceNumber
        );
      }
    } catch (error) {
      console.error("Error adding message: ", error);
    }
  };

  return (
    <div className="flex flex-col">
      <div className="flex-1 overflow-y-auto">
        {/* Render existing messages */}
        {messages
          /*           .sort((a, b) => {
            // Convert timestamp to milliseconds
            const timestampA = a.timestamp.seconds * 1000;
            const timestampB = b.timestamp.seconds * 1000;

            // Compare timestamps
            return timestampA - timestampB;
          }) */

          .map((message, index) => (
            <div key={index}>
              {message.utype ? (
                <div className="flex pt-4 items-center">
                  {appUserData && (
                    <div className="bg-cyan-700 h-8 w-8 rounded-full flex-shrink-0-full text-white flex items-center justify-center text-sm font-bold">
                      {appUserData.name.charAt(0).toUpperCase()}
                    </div>
                  )}
                  <div className="ml-3 bg-white py-2 px-4 shadow rounded-xl text-xs text-slate-600">
                    {message.message}
                  </div>
                </div>
              ) : (
                <div className="mt-2 flex items-center justify-start flex-row-reverse">
                  <img
                    src={`${process.env.PUBLIC_URL}/logo192.png`}
                    alt="Company Logo"
                    className="h-8 w-8"
                  />
                  <div className="mr-3 text-sm bg-indigo-100 py-2 px-4 shadow rounded-xl">
                    <div className="text-xs leading-5 text-slate-600">
                      {message.message}
                    </div>
                  </div>
                </div>
              )}
              <div className="text-right mt-4 text-xs text-slate-400">
                {message.timestamp &&
                  new Date(message.timestamp.seconds * 1000).toLocaleString()}
              </div>
            </div>
          ))}
        <div ref={messagesEndRef} /> {/* Ref for scrolling to the bottom */}
      </div>

      <form
        onSubmit={handleSubmit(handleFormSubmit)}
        className="flex justify-between items-center py-4 px-6 bg-gray-200"
      >
        <input
          type="text"
          placeholder="Write your message"
          {...register("message", { required: true })}
          className="flex-1 focus:outline-none focus:placeholder-gray-400 text-gray-600 text-xs placeholder-gray-600 pl-2 bg-gray-100 rounded-l-md py-2"
        />
        <button
          type="submit"
          className="rounded-r-md bg-sky-600 px-2.5 py-1.5 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <PaperAirplaneIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
        </button>
      </form>
    </div>
  );
};

export default IssueForm;

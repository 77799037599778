import { createContext, useContext, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
  confirmPasswordReset,
  sendEmailVerification,
} from "firebase/auth";
import { auth } from "../firebase";
import { useLocalStorage } from "./useLocalStorage";

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage("user", null);
  //const [isLoggedIn, setIsLoggedIn] = useState(false);

  const createUser = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  /*     const createUser = async (email, password, displayName) => {
        const { user } = await createUserWithEmailAndPassword(auth, email, password);
        await updateDisplayName(user, displayName);
        return user;
      };

      const updateDisplayName = async (user, displayName) => {
        await updateProfile(user, { displayName });
        setUser(user);
      }; */

  const signIn = (email, password) => {
    return signInWithEmailAndPassword(auth, email, password);
  };

  const emailVerify = (email, password) => {
    return sendEmailVerification(auth, email, password);
  };

  const logout = () => {
    return signOut(auth);
  };

  const passwordReset = async (email) => {
    return await sendPasswordResetEmail(auth, email);
  };

  const confirmThePasswordReset = async (oobCode, newPassword) => {
    if (!oobCode && !newPassword) {
      return;
    }

    return await confirmPasswordReset(auth, oobCode, newPassword);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      console.log(currentUser);
      setUser(currentUser);
    });
    return () => {
      unsubscribe();
    };
  }, []);
  return (
    <UserContext.Provider
      value={{
        emailVerify,
        createUser,
        confirmThePasswordReset,
        user,
        passwordReset,
        logout,
        signIn,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};

import React, { Fragment, useEffect, useState } from "react";
import { db } from "../firebase";
import { where, getDocs, collection, query } from "firebase/firestore";
import { UserAuth } from "../context/AuthContext";
import { useLocation } from "react-router-dom";
import GreenAlert from "./layouts/GreenAlert";
import BottomSheet from "./layouts/BottomSheet";
import {
  ClockIcon,
  PlusCircleIcon,
  ChevronUpIcon,
} from "@heroicons/react/20/solid";
import { Disclosure } from "@headlessui/react";

const Unscheduled = () => {
  const { user } = UserAuth();
  const [results, setResults] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isOffline = queryParams.get("offline");
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  const [formData, setFormData] = useState({
    service: "",
    facilityLocation: "",
    container: "",
  });

  const handleBottomSheetClose = () => {
    setIsBottomSheetOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user) {
          const userQuery = query(
            collection(db, "AppUsers"),
            where("userId", "==", user.uid)
          );
          const userSnapshot = await getDocs(userQuery);

          if (!userSnapshot.empty) {
            const userDoc = userSnapshot.docs[0];
            const userDocData = userDoc.data();

            const userFacilityId = userDocData.facilityId;

            const unscheduledQuery = query(
              collection(db, "Unscheduled"),
              where("facilityId", "==", userFacilityId)
            );
            const unscheduledSnapshot = await getDocs(unscheduledQuery);

            const unscheduledData = unscheduledSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            setResults(unscheduledData);
          }
        }
      } catch (error) {
        console.error("Error fetching schedules:", error);
      }
    };

    fetchData();
  }, [user]);

  const groupAndSortDataByFacilityLocation = (data) => {
    const groupedData = {};
    data.forEach((result) => {
      const facilityLocation = result.facilityLocation;
      if (!groupedData[facilityLocation]) {
        groupedData[facilityLocation] = [];
      }
      groupedData[facilityLocation].push(result);
    });

    Object.keys(groupedData).forEach((facilityLocation) => {
      groupedData[facilityLocation].sort((a, b) => {
        if (a.container < b.container) return -1;
        if (a.container > b.container) return 1;
        return 0;
      });
    });

    return groupedData;
  };

  const containerMappings = {};

  const serviceMappings = {};

  const formatServiceName = (serviceName) => {
    return serviceMappings[serviceName]
      ? serviceMappings[serviceName].name
      : serviceName;
  };

  const getBadgeColor = (serviceName) => {
    return serviceMappings[serviceName]
      ? serviceMappings[serviceName].color
      : "bg-lime-200";
  };

  const formatContainerName = (containerName) => {
    return containerMappings[containerName] || containerName;
  };

  const groupedAndSortedResults = groupAndSortDataByFacilityLocation(results);

  return (
    <div className="bg-white">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          {isOffline && (
            <GreenAlert
              message={
                "You are currently offline but your forms will be submitted when you are back online. Please check back here when you are back online to view your latest assessment results"
              }
            />
          )}
        </div>
      </div>

      <div className="flex items-center justify-between">
        <h2 className="text-base font-semibold leading-6 text-gray-900">
          Unscheduled Services
        </h2>
        <div className="w-10 h-10 flex items-center justify-center rounded-full bg-cyan-700">
          <ClockIcon className="h-5 w-5 text-white relative z-10" />
        </div>
      </div>
      <p className="mt-2 text-xs text-slate-600">
        Click on a site location to select a service.
      </p>
      <div className="mt-6 overflow-hidden border-t border-gray-100">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            {Object.keys(groupedAndSortedResults).map(
              (facilityLocation, index) => (
                <Fragment key={index}>
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button className="mb-4 flex w-full justify-between rounded-lg bg-gray-50 px-2 py-2 text-left text-xs font-medium text-slate-600 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500/75">
                          <span>
                            <span className="text-xs text-white rounded-md px-2 py-1 mr-2 bg-teal-500">
                              {facilityLocation}
                            </span>
                          </span>
                          <ChevronUpIcon
                            className={`${
                              open ? "rotate-180 transform" : ""
                            } h-5 w-5 text-cyan-700`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-2 pb-2 pt-2 text-sm text-gray-500">
                          {groupedAndSortedResults[facilityLocation].map(
                            (result, index) => (
                              <div
                                key={index}
                                onClick={() => {
                                  const formattedContainer =
                                    formatContainerName(result.container);

                                  setFormData({
                                    facilityLocation: result.facilityLocation,
                                    serviceName: result.services,
                                    container: formattedContainer,
                                  });
                                  setIsBottomSheetOpen(true);
                                }}
                                className="hover:bg-gray-100 cursor-pointer"
                              >
                                <div className="flex items-center gap-4 py-4">
                                  <div className="w-6 h-6 flex items-center justify-center rounded-full bg-teal-500">
                                    <PlusCircleIcon className="h-6 w-6 text-white relative z-10" />
                                  </div>
                                  <span>
                                    {formatServiceName(result.services)}
                                  </span>
                                  <div className="text-slate-900">
                                    <span className="text-xs hover:text-slate-900">
                                      {formatContainerName(result.container)}
                                    </span>
                                  </div>
                                  {result.numContainers && (
                                    <div className="text-xs text-slate-900">
                                      {result.numContainers} containers
                                    </div>
                                  )}
                                </div>
                              </div>
                            )
                          )}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </Fragment>
              )
            )}
          </div>
        </div>
      </div>

      {isBottomSheetOpen && (
        <BottomSheet
          formType="unscheduledReport"
          setOpen={handleBottomSheetClose}
          formData={formData}
          setFormData={setFormData}
        />
      )}
    </div>
  );
};

export default Unscheduled;

import { Disclosure } from "@headlessui/react";
import {
  CalendarDaysIcon,
  EnvelopeIcon,
  GlobeEuropeAfricaIcon,
  ShoppingCartIcon,
  WrenchIcon,
} from "@heroicons/react/20/solid";
import { MinusSmallIcon, PlusSmallIcon } from "@heroicons/react/24/outline";
import React from "react";
import { Link } from "react-router-dom";
//import ContactForm from "./ContactForm";
import { PhoneIcon } from "@heroicons/react/20/solid";
import {
  CurrencyPoundIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/24/solid";
const faqs = [
  {
    question: "How can I see an update on an issue?",
    answer:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
  },
  // More questions...
];

const people = [
  {
    name: "Bookings team",
    email: "bookings@futurfirst.co.uk",
    telephone: "01259 554040",
    imageUrl: <CalendarDaysIcon />,
  },
  {
    name: "Accounts",
    email: "accounts@futurfirst.co.uk",
    telephone: "01259 554040",
    imageUrl: <CurrencyPoundIcon />,
  },
  {
    name: "Recycle & Self-Billing queries",
    email: "info@futurfirst.co.uk",
    telephone: "01259 554040",
    imageUrl: <GlobeEuropeAfricaIcon />,
  },
  {
    name: "Maintenance/Service/Equipment",
    email: "info@futurfirst.co.uk",
    telephone: "01259 554040",
    imageUrl: <WrenchIcon />,
  },
  {
    name: "Baling Wire – Orders",
    email: "bookings@futurfirst.co.uk",
    telephone: "01259 554040",
    imageUrl: <ShoppingCartIcon />,
  },
];

export default function FrequentlyAsked() {
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-4xl divide-y divide-gray-900/10">
        {/*           <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">
            Frequently asked questions
          </h2> */}
        <div className="mx-auto text-sm p-4">
          <h2 className="text-sm text-center font-bold mb-4">CONTACTS SHEET</h2>
          <p className="mb-4 text-sm text-center text-sky-600">
            <strong>OFFICE HOURS:</strong> 08.30AM TO 17.30PM – MONDAY TO FRIDAY
          </p>
          <p className="mb-4">
            <strong>Collection Requests</strong> If you would like to order a
            waste collection, please contact our Bookings Team: Tel:{" "}
            <a href="tel:01249554040">01249 554040</a> or Email:{" "}
            <a href="mailto:bookings@futurfirst.co.uk" className="text-sky-600">
              bookings@futurfirst.co.uk
            </a>
            . In general, if you order a waste service before 11.00am, it will
            be completed the following working day. Any orders taken after
            11.00am will usually be collected within 48 hours.
          </p>
          <p className="mb-4">
            Once the collection has been booked, we will confirm with you either
            by phone or email when you can expect the collection to take place.
          </p>
          <p className="mb-4">
            For collections of baled recyclable materials, we aim to get a
            vehicle booked in within 5 working days, again we will always
            confirm the collection day with you.
          </p>
          <h3 className="text-sm underline font-semibold mb-2">
            Useful Contacts within our Head Office:
          </h3>

          <ul
            role="list"
            className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3"
          >
            {people.map((person) => (
              <li
                key={person.email}
                className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
              >
                <div className="flex w-full items-center justify-between space-x-6 p-6">
                  <div className="flex-1 truncate">
                    <div className="flex items-center space-x-3">
                      <h3 className="truncate text-sm font-medium text-gray-900">
                        {person.name}
                      </h3>
                      {/*                       <span className="inline-flex flex-shrink-0 items-center rounded-full bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20"></span>
                       */}{" "}
                    </div>
                    <p className="mt-1 truncate text-sm text-gray-500"></p>
                  </div>
                  <p className="h-6 w-6 rounded-full text-sky-600">
                    {person.imageUrl}
                  </p>
                </div>
                <div>
                  <div className="-mt-px flex divide-x divide-gray-200">
                    <div className="flex w-0 flex-1">
                      <a
                        href={`mailto:${person.email}`}
                        className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                      >
                        <EnvelopeIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        Email
                      </a>
                    </div>
                    <div className="-ml-px flex w-0 flex-1">
                      <a
                        href={`tel:${person.telephone}`}
                        className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-4 text-sm font-semibold text-gray-900"
                      >
                        <PhoneIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        Call
                      </a>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>

          <p className="mt-8 mb-4">
            Director/Office Manager - Sam Wileman{" "}
            <a
              href="mailto:sam.wileman@futurfirst.co.uk"
              className="text-sky-600"
            >
              sam.wileman@futurfirst.co.uk
            </a>
          </p>
          <p className="mb-4">
            <strong>Out of hours and emergencies</strong> If you have a
            Futurfirst machine breakdown or for urgent service issues outside of
            office hours, please contact either your Account Manager or call our
            Head Office where you will be directed to our out of hours contacts.
          </p>
          <p className="mb-4">
            <strong>Recycle and Self-Billing</strong> If you have any specific
            enquiries on your recycling, please contact:{" "}
            <a href="tel:01249554040">01249 554040</a>
          </p>
          <p className="mb-4">
            <strong>Maintenance</strong> If you would like to report a
            maintenance issue relating to Futurfirst equipment, please contact:{" "}
            <a href="tel:01249554040">01249 554040</a>
          </p>
          <p className="mb-4">
            <strong>Baling Wire</strong> If you would like to order more
            supplies of baling wire, please email your purchase order to our
            Bookings team:{" "}
            <a href="mailto:bookings@futurfirst.co.uk" className="text-sky-600">
              bookings@futurfirst.co.uk
            </a>
          </p>
          <p className="mb-4 text-xs text-sky-400">
            Futur First Limited, 1 Langley Building, Kington Park, Malmesbury
            Road, Chippenham, Wiltshire SN15 5PZ. Tel:{" "}
            <a href="tel:01249554040" className="text-sky-400">
              01249 554040
            </a>{" "}
            {/* Wrap EnvelopeIcon in a span */}
            <a href="mailto:info@futurfirst.co.uk" className="text-sky-400">
              info@futurfirst.co.uk
            </a>{" "}
            <Link
              to="https://www.futurfirst.co.uk"
              target="_blank"
              className="text-sky-400"
            >
              www.futurfirst.co.uk
            </Link>
          </p>
        </div>

        {/*           <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <PlusSmallIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">
                        {faq.answer}
                      </p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl> */}
      </div>
    </div>
  );
}

import * as React from "react";
import { useForm } from "react-hook-form";
import isEmpty from "lodash/isEmpty";
import { useState, useEffect } from "react";
import { db, storage } from "../firebase";
import { getDocs, collection, addDoc, query, where } from "firebase/firestore";
import { UserAuth } from "../context/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { toast } from "react-hot-toast";
import { showAlert } from "tailwind-toastify";
import { FlagIcon } from "@heroicons/react/20/solid";

export default function AdhocForm({ formData, setFormData }) {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();
  // eslint-disable-next-line
  const [successMsg, setSuccessMsg] = useState("");
  // eslint-disable-next-line
  const [facility, setFacility] = useState(""); // Assuming 'facility' is a string, adjust the type accordingly
  // eslint-disable-next-line
  const [name, setName] = useState("");

  const { user } = UserAuth();
  const uid = user ? user.uid : null; // Check if user exists before accessing the UID
  const navigate = useNavigate();
  //const { facility } = useContext(FacilityContext);
  //  const submitDate = new Date().toLocaleDateString("en-GB");
  const submitDate = new Date().toLocaleString("en-GB");

  //console.log("Facility value:", facility);

  const location = useLocation();

  useEffect(() => {
    // Function to fetch data from AppUsers collection
    const fetchUserData = async () => {
      try {
        const appUsersQuerySnapshot = await query(
          collection(db, "AppUsers"),
          where("userId", "==", uid)
        );
        const appUsersDocs = await getDocs(appUsersQuerySnapshot);

        if (!isEmpty(appUsersDocs.docs)) {
          const userData = appUsersDocs.docs[0].data();
          const { name, facility } = userData;

          // Set the fetched data in the form using setValue
          // eslint-disable-next-line
          setValue("name", name);
          // eslint-disable-next-line
          setValue("facility", facility);
        } else {
          // Handle the case when no document is found
          console.error("User data not found");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    // Call the function to fetch user data
    if (uid) {
      fetchUserData();
    }
  }, [uid, setValue]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [location]);

  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    function handleOffline() {
      setIsOnline(false);
    }

    function handleOnline() {
      setIsOnline(true);
    }

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, []);

  const onSubmit = async (formData) => {
    let referenceNumber; // Declare referenceNumber variable

    if (isOnline) {
      // Perform form submission logic for online mode
      console.log("Form submitted online");
      navigate("/");
    } else {
      // Perform form submission logic for offline mode
      console.log("Form submitted offline");
      navigate("/");
    }

    try {
      let fileMetadata = null; // Initialize fileMetadata as null

      if (formData.file && formData.file.length > 0) {
        const file = formData.file[0];
        // Create a reference to the storage location
        const storageRef = ref(storage, `images/adhoc/${file.name}`);

        // Upload the file to storage
        await uploadBytes(storageRef, file);

        // Get the file URL
        const fileUrl = await getDownloadURL(storageRef);

        fileMetadata = {
          name: file.name,
          type: file.type,
          size: file.size,
          url: fileUrl,
        };
      }
      referenceNumber = generateReferenceNumber(formData.issue_type);

      await addDoc(collection(db, "Issue"), {
        submitDate: submitDate,
        name: formData.name,
        facility: formData.facility, // Use the form data
        uid: formData.uid, // Use the form data
        issue_type: formData.issue_type,
        issue_message: formData.issue_message,
        referenceNumber: referenceNumber,
        file: fileMetadata,
        isRead: false, // Set isRead to false by default
      });

      toast.success(
        `We've received your support request and will get back to you ASAP. Reference Number: ${referenceNumber}`,
        {
          duration: 4000, // Duration in milliseconds
        }
      );

      console.log("Form submitted", formData);
      reset();
    } catch (error) {
      console.error("Error uploading file to Firebase Storage:", error);
    }
  };

  // Function to generate a custom reference number based on issue type
  const generateReferenceNumber = (issueType) => {
    // You can customize this logic based on your requirements
    const prefix = issueType === "Adhoc" ? "AH" : "CO";
    const randomDigits = Math.floor(10000 + Math.random() * 90000);
    return `${prefix}${randomDigits}`;
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          {...register("facility")}
          type="hidden"
          value=""
          name="facility"
        />
        <input {...register("uid")} type="hidden" value={uid} name="uid" />
        {/* Add additional hidden fields for data from AppUsers */}
        <input {...register("name")} type="hidden" value="" name="name" />
        <input
          {...register("issue_type")}
          type="hidden"
          value="Adhoc"
          name="issue_type"
        />
        <div className="space-y-12">
          {successMsg && <p className="success-msg">{successMsg}</p>}

          <div className="border-b border-gray-900/10 pb-1">
            {errors.root?.serverError && (
              <p>Something went wrong, and please try again.</p>
            )}
            <div className="flex items-center justify-between">
              <h2 className="text-base font-semibold leading-6 text-gray-900">
                Adhoc collection
              </h2>
              <div className="w-10 h-10 flex items-center justify-center rounded-full bg-cyan-700">
                <FlagIcon className="h-5 w-5 text-white relative z-10" />
              </div>
            </div>
            <p className="mt-2 text-xs text-slate-600">
              Please Use this form to request an adhoc collection of service.
            </p>
            <div className="w-full px-4 pt-4"></div>
            <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6"></div>
          </div>

          <div className="border-b border-gray-900/10 pb-12">
            <p className="mt-1 text-sm leading-6 text-gray-600"></p>
            <div className="mt-10 space-y-10">
              <fieldset>
                <legend className="text-sm font-semibold leading-6 text-gray-900">
                  Please give some details
                </legend>
                <div className="mt-1 space-y-6">
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    <div className="flex flex-col">
                      <textarea
                        {...register("issue_message", {
                          required: "Please enter an explanation",
                        })}
                        aria-invalid={
                          errors["issue_message"] ? "true" : "false"
                        }
                        className="form-textarea mt-1 block w-full p-2 border rounded-md"
                        style={{ marginBottom: "1rem" }} // Adjust margin as needed
                      />
                      <input
                        type="file"
                        name="file"
                        id="file"
                        {...register("file")}
                        className="w-full p-2 border rounded-md"
                      />
                    </div>
                  </label>
                  {errors.issue_message && (
                    <div className="text-red-600">
                      {errors.issue_message.message}
                    </div>
                  )}
                </div>
              </fieldset>
            </div>
          </div>
          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              disabled={isSubmitting}
              type="submit"
              className="rounded-md bg-sky-100 px-3 py-2 text-sm font-semibold text-sky-700 shadow-sm hover:bg-sky-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

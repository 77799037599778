import React, { useEffect, useState } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
  updateDoc,
  doc,
} from "firebase/firestore";
import { db } from "../firebase";
import { UserAuth } from "../context/AuthContext";
import NewNotificationLayout from "./layouts/MessageNotificationWithCount";
import { EnvelopeIcon } from "@heroicons/react/24/solid";
import { Menu } from "@headlessui/react";
// ... (import statements)

const MessageNotifications = () => {
  const { user } = UserAuth();
  const [appUserData, setAppUserData] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [unreadMessages, setUnreadMessages] = useState(0);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (user) {
          const userQuery = query(
            collection(db, "AppUsers"),
            where("userId", "==", user.uid)
          );
          const userSnapshot = await getDocs(userQuery);
          if (!userSnapshot.empty) {
            const userData = userSnapshot.docs[0].data();
            setAppUserData(userData);
          }
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [user]);

  useEffect(() => {
    if (!appUserData) return; // Return if appUserData is not available yet

    setLoading(true);

    const issueQuery = query(
      collection(db, "Issue"),
      where("facility", "==", appUserData.facility) // Use appUserData.facility to filter Issue documents
    );

    const unsubscribeFunctions = [];

    const unsubscribe = onSnapshot(issueQuery, (querySnapshot) => {
      const messagesData = {};

      querySnapshot.forEach((doc) => {
        const referenceNumber = doc.data().referenceNumber; // Access the referenceNumber field
        const issueDocRef = doc.ref;
        const messageCollectionRef = collection(issueDocRef, "Message");

        const messageUnsubscribe = onSnapshot(
          messageCollectionRef,
          (messageSnapshot) => {
            messageSnapshot.docChanges().forEach((change) => {
              const message = change.doc.data();
              const messageId = change.doc.id;

              if (change.type === "added" || change.type === "modified") {
                if (!message.messageRead && message.utype === false) {
                  messagesData[messageId] = {
                    id: messageId,
                    issueId: doc.id, // Add issueId to the message data
                    referenceNumber, // Add referenceNumber to the message data
                    ...message,
                  };
                }
              } else if (change.type === "removed") {
                delete messagesData[messageId];
              }
            });
            setMessages(Object.values(messagesData));
            setUnreadMessages(Object.values(messagesData).length); // Update the unread message count
          }
        );
        unsubscribeFunctions.push(messageUnsubscribe); // Add the messageUnsubscribe function to the array
      });

      setLoading(false);
    });
    unsubscribeFunctions.push(unsubscribe); // Add the unsubscribe function to the array

    return () => {
      unsubscribeFunctions.forEach((unsub) => unsub()); // Call all the unsubscribe functions in the array
    };
  }, [appUserData]);

  const handleNotificationClick = async () => {
    try {
      if (user) {
        setIsOpen(true); // Open the dropdown menu
      }
    } catch (error) {
      console.error("Error opening notifications:", error);
    }
  };

  const handleMessageRead = async (issueId, messageId) => {
    try {
      if (user) {
        // Get a reference to the Message sub-collection
        const messageRef = doc(db, "Issue", issueId, "Message", messageId);

        // Update the messageRead field
        await updateDoc(messageRef, {
          messageRead: true,
        });

        console.log("Message marked as read");
      }
      setUnreadMessages((prevUnreadMessages) => prevUnreadMessages - 1);
      setMessages((prevMessages) =>
        prevMessages.filter((message) => message.id !== messageId)
      );
    } catch (error) {
      console.error("Error updating message:", error);
    }
  };

  return (
    <div className="relative mr-4">
      <Menu as="div">
        <div>
          <Menu.Button
            onClick={handleNotificationClick}
            className="rounded-full bg-white p-1 text-sky-600 hover:text-sky-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
          >
            <span className="sr-only">View notifications</span>
            <EnvelopeIcon className="h-6 w-6" aria-hidden="true" />
            {unreadMessages > 0 && (
              <div className="px-1 bg-lime-500 rounded-full text-center text-white text-xs absolute -top-1 -end-1">
                {unreadMessages}
              </div>
            )}
          </Menu.Button>
        </div>
        <Menu.Items
          style={{ display: isOpen ? "block" : "none" }} // Only show when open
        >
          {loading ? (
            <p>Loading...</p>
          ) : (
            <div>
              {messages.map((message) => (
                <Menu.Item key={message.id} className="my-2">
                  <NewNotificationLayout
                    key={message.id}
                    message={message.message}
                    referenceNumber={message.referenceNumber}
                    onClose={() =>
                      handleMessageRead(message.issueId, message.id)
                    }
                  />
                </Menu.Item>
              ))}
            </div>
          )}
        </Menu.Items>
      </Menu>
    </div>
  );
};

export default MessageNotifications;

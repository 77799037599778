import React from "react";
import { Link } from "react-router-dom";

const FullWidthButton = ({ title, subtitle, icon: Icon, gradient }) => {
  return (
    <div className="w-full">
      <button
        className={`w-full ${gradient} p-4 rounded-xl mb-4 pt-8 pb-8 flex flex-col items-center text-white`}
      >
        <div className="flex items-center justify-between w-full">
          <div className="text-left">
            <h3 className="text-sm font-bold">{title}</h3>
            <p className="text-xs">{subtitle}</p>
          </div>
          {Icon && <Icon className="w-6 h-6" />}
        </div>
      </button>
    </div>
  );
};

export default FullWidthButton;

import {
  CalendarDaysIcon,
  InformationCircleIcon,
  MapPinIcon,
  PlusIcon,
  UserPlusIcon,
  WrenchScrewdriverIcon,
  ChevronUpIcon,
} from "@heroicons/react/20/solid";
import React, { Fragment, useEffect, useState } from "react";
import { db } from "../firebase";
import { where, getDocs, collection, query } from "firebase/firestore";
import { UserAuth } from "../context/AuthContext";
import { useLocation, Link } from "react-router-dom";
import GreenAlert from "./layouts/GreenAlert";
import BottomSheet from "./layouts/BottomSheet";
import { motion, AnimatePresence } from "framer-motion";
import { Disclosure } from "@headlessui/react";
import {
  EnvelopeIcon,
  FlagIcon,
  PlusCircleIcon,
  PlusSmallIcon,
  TruckIcon,
} from "@heroicons/react/24/solid";
import { Badge } from "./layouts/catalyst/badge";
import { Avatar } from "./layouts/catalyst/avatar";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableHeader,
  TableCell,
} from "./layouts/catalyst/table";
import FlagInstructions from "./FlagInstructions";

const NewEquipment = () => {
  const { user } = UserAuth();
  const [results, setResults] = useState([]);
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false); // New state for bottom sheet
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isOffline = queryParams.get("offline");
  const [formData, setFormData] = useState({
    equipmentName: "",
    facilityLocation: "",
    equipmentId: "",
  });

  const handleBottomSheetClose = () => {
    setIsBottomSheetOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user) {
          const userQuery = query(
            collection(db, "AppUsers"),
            where("userId", "==", user.uid)
          ); // Change 'Schedule' to your actual collection name
          const userSnapshot = await getDocs(userQuery);

          if (!userSnapshot.empty) {
            const userDoc = userSnapshot.docs[0];
            const userDocData = userDoc.data();

            const userFacilityId = userDocData.facilityId;

            const equipmentQuery = query(
              collection(db, "Equipment"),
              where("facilityId", "==", userFacilityId)
            ); // Change 'Schedule' to your actual collection name
            const equipmentSnapshot = await getDocs(equipmentQuery);

            // Get the data from the "Equipment" collection
            const equipmentData = equipmentSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            setResults(equipmentData);
          }
        }
      } catch (error) {
        console.error("Error fetching equipment:", error);
      }
    };

    fetchData();
  }, [user]); // Include 'user' as a dependency

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  // Create a function to group data by services
  const groupDataByLocation = (data) => {
    const groupedData = {};
    data.forEach((result) => {
      const facilityLocation = result.facilityLocation;
      if (!groupedData[facilityLocation]) {
        groupedData[facilityLocation] = [];
      }
      groupedData[facilityLocation].push(result);
    });

    // Sort data within each group alphabetically by equipmentName
    Object.keys(groupedData).forEach((facilityLocation) => {
      groupedData[facilityLocation].sort((a, b) => {
        if (a.equipmentName < b.equipmentName) return -1;
        if (a.equipmentName > b.equipmentName) return 1;
        return 0;
      });
    });

    return groupedData;
  };

  const groupedResults = groupDataByLocation(results);

  return (
    <div className="bg-white">
      <div className="flex items-center justify-between">
        <h2 className="text-base font-semibold leading-6 text-gray-900">
          Equipment
        </h2>

        <div className="w-10 h-10 flex items-center justify-center rounded-full bg-cyan-800">
          <WrenchScrewdriverIcon className="h-5 w-5 text-white relative z-10" />
        </div>
      </div>
      <p className="mt-2 text-xs text-slate-600">
        Click on a location and select the equipment you wish to report an issue
        with.
      </p>
      <div className="mt-6 overflow-hidden border-t border-gray-100">
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <Table
              striped
              dense
              className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)]"
            >
              <TableBody>
                {Object.keys(groupedResults).map((facilityLocation, index) => (
                  <Fragment key={index}>
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="mb-4 flex w-full justify-between rounded-lg bg-gray-50 px-2 py-2 text-left text-xs font-medium text-slate-600 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500/75">
                            <span>
                              <span className="text-xs text-white rounded-md px-2 py-1 mr-2 bg-teal-500">
                                {facilityLocation}
                              </span>
                            </span>
                            <ChevronUpIcon
                              className={`${
                                open ? "rotate-180 transform" : ""
                              } h-5 w-5 text-cyan-700`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="px-2 pb-2 pt-2 text-sm text-gray-500">
                            {groupedResults[facilityLocation].map(
                              (result, index) => (
                                <div
                                  key={index}
                                  onClick={() => {
                                    console.log("Setting formData:", {
                                      equipmentName: result.equipmentName,
                                      facilityLocation: result.facilityLocation,
                                      equipmentId: result.equipmentId,
                                    });
                                    setFormData({
                                      equipmentName: result.equipmentName,
                                      facilityLocation: result.facilityLocation,
                                      equipmentId: result.equipmentId,
                                    });
                                    setIsBottomSheetOpen(true);
                                    console.log(
                                      "isBottomSheetOpen:",
                                      isBottomSheetOpen
                                    );
                                  }}
                                  className="hover:bg-gray-100 cursor-pointer"
                                >
                                  <div className="flex items-center gap-4 py-4">
                                    <div className="w-6 h-6 flex items-center justify-center rounded-full bg-teal-500">
                                      <PlusCircleIcon className="h-6 w-6 text-white relative z-10" />
                                    </div>
                                    <div className="flex items-center gap-4">
                                      <div>
                                        <div className="text-xs font-medium">
                                          {result.equipmentName}
                                        </div>
                                        <div className="text-xs text-gray-500">
                                          <span className="hover:text-gray-700">
                                            SN: {result.equipmentId}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </Fragment>
                ))}
              </TableBody>
            </Table>
          </div>
        </div>
      </div>
      {isBottomSheetOpen && (
        <BottomSheet
          formType="equipmentReport"
          setOpen={handleBottomSheetClose}
          formData={formData}
          setFormData={setFormData}
        />
      )}
    </div>
  );
};

export default NewEquipment;

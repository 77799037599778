import React from "react";
import { Navigate } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";

const ProtectedRoute = ({ children }) => {
  const { user, logout } = UserAuth();

  console.log("User:", user); // Verify if the user value is correctly obtained
  //console.log('Logout Function:', logout); // Verify if the logout function is correctly obtained

  if (!user) {
    return <Navigate to="/" />;
  }
  return children;
};

export default ProtectedRoute;

import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  EllipsisHorizontalIcon,
  MapPinIcon,
  TruckIcon,
  TrashIcon,
  CalendarDaysIcon,
  CircleStackIcon,
} from "@heroicons/react/20/solid";
import { Menu, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { db } from "../firebase";
import { where, getDocs, collection, query } from "firebase/firestore";
import { UserAuth } from "../context/AuthContext";
import { useLocation, Link } from "react-router-dom";
import GreenAlert from "./layouts/GreenAlert";
import BottomSheet from "./layouts/BottomSheet";
import { Badge } from "./layouts/catalyst/badge";
import { Button } from "./layouts/catalyst/button";
import { ClipboardDocumentListIcon } from "@heroicons/react/24/outline";
import { Avatar } from "./layouts/catalyst/avatar";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableHeader,
  TableCell,
} from "./layouts/catalyst/table";
import { Disclosure } from "@headlessui/react";
import {
  ChevronUpIcon,
  FlagIcon,
  PlusCircleIcon,
} from "@heroicons/react/20/solid";

const NewSchedule = () => {
  const { user } = UserAuth();
  const [results, setResults] = useState([]);
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false); // New state for bottom sheet
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isOffline = queryParams.get("offline");
  const [formData, setFormData] = useState({
    serviceName: "",
    facilityLocation: "",
    container: "",
  });

  const handleBottomSheetClose = () => {
    setIsBottomSheetOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user) {
          const userQuery = query(
            collection(db, "AppUsers"),
            where("userId", "==", user.uid)
          ); // Change 'Schedule' to your actual collection name
          const userSnapshot = await getDocs(userQuery);

          if (!userSnapshot.empty) {
            const userDoc = userSnapshot.docs[0];
            const userDocData = userDoc.data();

            const userFacilityId = userDocData.facilityId;

            const scheduleQuery = query(
              collection(db, "Schedule"),
              where("facilityId", "==", userFacilityId)
            ); // Change 'Schedule' to your actual collection name
            const scheduleSnapshot = await getDocs(scheduleQuery);

            // Get the data from the "Equipment" collection
            const scheduleData = scheduleSnapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));

            setResults(scheduleData);
          }
        }
      } catch (error) {
        console.error("Error fetching schedules:", error);
      }
    };

    fetchData();
  }, [user]); // Include 'user' as a dependency

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const containerMappings = {
    LgLitresBin: "1100 Litres Bin",
    MdLitresBin: "660 Litres Bin",
    SmLitresBin: "240 Litres Bin",
    SmdLitreBin: "360 Litres Bin",
    YardFel: "8 Yard Fel",
    YardRel: "12 Yard Rel",
    FourteenYard: "14 Yd Portable Compactor + Tipper",
  };

  const serviceMappings = {
    generalWaste: { name: "General Waste", color: "bg-yellow-500" },
    food: { name: "Food", color: "bg-red-500" },
    glass: { name: "Glass", color: "bg-sky-700" },
    dryMixed: { name: "Dry Mixed Recyling", color: "bg-cyan-700" },
    cardboard: { name: "Cardboard Recycling", color: "bg-teal-100" },
    // Add more mappings as needed
  };

  const formatServiceName = (serviceName) => {
    // Use the mapping if available, or return the original service name
    return serviceMappings[serviceName]
      ? serviceMappings[serviceName].name
      : serviceName;
  };

  const getBadgeColor = (serviceName) => {
    // Use the mapping if available, or default to lime
    return serviceMappings[serviceName]
      ? serviceMappings[serviceName].color
      : "bg-lime-200";
  };

  const formatContainerName = (containerName) => {
    return containerMappings[containerName] || containerName;
  };

  // Create a function to group data by services
  const groupDataByFacilityLocation = (data) => {
    const groupedData = {};
    data.forEach((result) => {
      const facilityLocation = result.facilityLocation;
      if (!groupedData[facilityLocation]) {
        groupedData[facilityLocation] = [];
      }
      groupedData[facilityLocation].push(result);
    });
    return groupedData;
  };

  // Use the function to group results by facilityLocation
  const groupedResults = groupDataByFacilityLocation(results);

  return (
    <div className="bg-white">
      <div className="flex items-center justify-between">
        <h2 className="text-base font-semibold leading-6 text-gray-900">
          Scheduled Services
        </h2>
        <div className="w-10 h-10 flex items-center justify-center rounded-full bg-sky-700">
          <CalendarDaysIcon className="h-5 w-5 text-white relative z-10" />
        </div>
      </div>
      <p className="mt-2 text-xs text-slate-600">
        Open the dropdown for more details and to report an issue.
      </p>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8"></div>
      <div className="mt-6 overflow-hidden border-t border-gray-100">
        <div className="mx-auto max-w-7xl">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            {/*             <Table
              striped
              dense
              className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)]"
            >
              <TableBody> */}
            {Object.keys(groupedResults).map((facilityLocation, index) => (
              <Fragment key={index}>
                {/* Render disclosure panel for each facilityLocation */}
                <Disclosure>
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="mb-4 flex w-full justify-between rounded-lg bg-gray-50 px-2 py-2 text-left text-xs font-medium text-slate-600 hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-gray-500/75">
                        <span>
                          <span className="text-xs text-white rounded-md px-2 py-1 mr-2 bg-teal-500">
                            {facilityLocation}
                          </span>
                        </span>
                        <ChevronUpIcon
                          className={`${
                            open ? "rotate-180 transform" : ""
                          } h-5 w-5 text-cyan-700`}
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="px-2 pb-2 pt-2 text-sm text-gray-500">
                        {/* Render service rows for this facilityLocation */}
                        {groupedResults[facilityLocation].map(
                          (result, index) => (
                            <div
                              key={index}
                              onClick={() => {
                                const formattedContainer = formatContainerName(
                                  result.container
                                ); // Apply mapping here

                                setFormData({
                                  facilityLocation: result.facilityLocation,
                                  serviceName: result.services,
                                  container: formattedContainer,
                                });
                                setIsBottomSheetOpen(true);
                              }}
                              className="hover:bg-gray-100 cursor-pointer"
                            >
                              {/* Render service row */}
                              <div className="flex items-center gap-4 py-4">
                                <div className="w-6 h-6 flex items-center justify-center rounded-full bg-teal-500">
                                  <PlusCircleIcon className="h-6 w-6 text-white relative z-10" />
                                </div>
                                <span
                                  className={`text-xs text-white ${getBadgeColor(
                                    result.services
                                  )} rounded-md px-2 py-1 mr-2`}
                                >
                                  {formatServiceName(result.services)}
                                </span>
                                <div className="text-slate-900">
                                  <span className="text-xs hover:text-slate-900">
                                    {formatContainerName(result.container)}
                                  </span>
                                </div>
                                {result.numContainers && (
                                  <div className="text-xs text-slate-900">
                                    {result.numContainers} containers
                                  </div>
                                )}
                                <div className="text-xs text-slate-900">
                                  {result.collectionDay
                                    .map((day) => {
                                      if (day.startsWith("Fort")) {
                                        return day; // Keep the original value if it starts with "Fortnightly"
                                      } else {
                                        return day.substring(0, 3); // Otherwise, just get the first three characters
                                      }
                                    })
                                    .join(", ")}
                                </div>
                              </div>
                            </div>
                          )
                        )}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              </Fragment>
            ))}
            {/*               </TableBody>
            </Table> */}
          </div>
        </div>
      </div>
      {isBottomSheetOpen && (
        <BottomSheet
          formType="scheduleReport"
          setOpen={handleBottomSheetClose}
          formData={formData}
          setFormData={setFormData}
        />
      )}
    </div>
  );
};

export default NewSchedule;

import React from "react";

const CustomButton = ({ children, onClick }) => (
  <button
    onClick={onClick}
    className="w-full mb-4 pb-4 rounded-lg bg-sky-100 px-4 py-2 text-left text-sm font-medium text-sky-900 hover:bg-sky-200 focus:outline-none focus-visible:ring focus-visible:ring-sky-500/75"
  >
    {children}
  </button>
);

export default CustomButton;

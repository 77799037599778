import * as React from "react";
import { useForm } from "react-hook-form";
import isEmpty from "lodash/isEmpty";
import { useState, useEffect } from "react";
import { db, storage } from "../firebase";
import { getDocs, collection, addDoc, query, where } from "firebase/firestore";
import { UserAuth } from "../context/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
import { showAlert } from "tailwind-toastify";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { toast } from "react-hot-toast";

export default function UnscheduledReportForm({
  formData,
  setFormData,
  facilityLocation,
  container,
}) {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();
  // eslint-disable-next-line
  const [successMsg, setSuccessMsg] = useState("");
  // eslint-disable-next-line
  const [facility, setFacility] = useState(""); // Assuming 'facility' is a string, adjust the type accordingly
  // eslint-disable-next-line
  const [name, setName] = useState("");

  const { user } = UserAuth();
  const uid = user ? user.uid : null; // Check if user exists before accessing the UID
  const navigate = useNavigate();
  //const { facility } = useContext(FacilityContext);

  const submitDate = new Date().toLocaleString("en-GB");

  //  const submitDate = new Date().toLocaleString("en-GB");

  //console.log("Facility value:", facility);

  const location = useLocation();

  useEffect(() => {
    // Function to fetch data from AppUsers collection
    const fetchUserData = async () => {
      try {
        const appUsersQuerySnapshot = await query(
          collection(db, "AppUsers"),
          where("userId", "==", uid)
        );
        const appUsersDocs = await getDocs(appUsersQuerySnapshot);

        if (!isEmpty(appUsersDocs.docs)) {
          const userData = appUsersDocs.docs[0].data();
          const { name, facility } = userData;

          // Set the fetched data in the form using setValue
          // eslint-disable-next-line
          setValue("name", name);
          // eslint-disable-next-line
          setValue("facility", facility);
          setValue("facilityLocation", facilityLocation);
          setValue("container", container);
        } else {
          // Handle the case when no document is found
          console.error("User data not found");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    // Call the function to fetch user data
    if (uid) {
      fetchUserData();
    }
  }, [uid, setValue, name, facility, container, facilityLocation]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [location]);

  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    function handleOffline() {
      setIsOnline(false);
    }

    function handleOnline() {
      setIsOnline(true);
    }

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, []);

  const onSubmit = async (formData) => {
    let referenceNumber; // Declare referenceNumber variable

    if (isOnline) {
      // Perform form submission logic for online mode
      console.log("Form submitted online");
      navigate("/");
    } else {
      // Perform form submission logic for offline mode
      console.log("Form submitted offline");
      navigate("/");
    }
    try {
      let fileMetadata = null; // Initialize fileMetadata as null

      if (formData.file && formData.file.length > 0) {
        const file = formData.file[0];
        // Create a reference to the storage location
        const storageRef = ref(storage, `images/${file.name}`);

        // Upload the file to storage
        await uploadBytes(storageRef, file);

        // Get the file URL
        const fileUrl = await getDownloadURL(storageRef);

        fileMetadata = {
          name: file.name,
          type: file.type,
          size: file.size,
          url: fileUrl,
        };
      }

      referenceNumber = generateReferenceNumber(formData.issue_type);

      await addDoc(collection(db, "Issue"), {
        submitDate: submitDate,
        name: formData.name,
        facility: formData.facility, // Use the form data
        uid: formData.uid, // Use the form data
        issue_message: formData.issue_message,
        issue_type: formData.issue_type,
        issue_status: formData.issue_status,
        referenceNumber: referenceNumber,
        facilityLocation: formData.facilityLocation,
        container: formData.container,
        isRead: false, // Set isRead to false by default
        unscheduled: formData.unscheduled,
        file: fileMetadata, // Assign fileMetadata to file field
      });

      toast.success(
        `We've received your support request and will get back to you ASAP. Reference Number: ${referenceNumber}`,
        {
          duration: 4000, // Duration in milliseconds
        }
      );

      console.log("Form submitted", formData);
      reset();
    } catch (error) {
      console.error("Error uploading file to Firebase Storage:", error);
    }
  };

  // Function to generate a custom reference number based on issue type
  const generateReferenceNumber = (issueType) => {
    // You can customize this logic based on your requirements
    const prefix = issueType === "Unscheduled" ? "UNS" : "CO";
    const randomDigits = Math.floor(10000 + Math.random() * 90000);
    return `${prefix}${randomDigits}`;
  };
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} className="px-4">
        {/* Hidden fields */}
        <input {...register("uid")} type="hidden" value={uid} name="uid" />
        <input {...register("name")} type="hidden" value="" name="name" />
        <input type="hidden" {...register("issue_status")} value="true" />
        <input
          {...register("issue_type")}
          type="hidden"
          value="Unscheduled"
          name="issue_type"
        />

        {successMsg && <p className="success-msg">{successMsg}</p>}

        <div className="pb-1 pt-1 rounded-lg bg-white">
          {errors.root?.serverError && (
            <p>Something went wrong, and please try again.</p>
          )}

          <h1 className="text-center text-xl font-semibold mb-4">
            Request a service
          </h1>

          <p className="text-center text-sm mb-4">
            Please use this form to request an unscheduled service. We'll get
            back to you ASAP.
          </p>
        </div>

        <div className="space-y-4">
          <fieldset disabled="true">
            <div className="sm:col-span-4">
              <div className="mt-2">
                <div className="text-xs flex rounded-md shadow-sm focus-within:ring-2 focus-within:ring-inset focus-within:ring-slate-300 sm:max-w-md">
                  <input
                    {...register("container")}
                    type="text"
                    value={container}
                    className="block flex-1 rounded-lg border-0 bg-slate-100 py-1.5 pl-1 text-slate-600 placeholder:text-white focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
            <div className="sm:col-span-4">
              <div className="mt-2">
                <div className="text-xs flex rounded-md shadow-sm focus-within:ring-2 focus-within:ring-inset focus-within:ring-slate-300 sm:max-w-md">
                  <input
                    {...register("facilityLocation")}
                    type="text"
                    value={facilityLocation}
                    className="block flex-1 rounded-lg border-0 bg-slate-100 py-1.5 pl-1 text-slate-600 placeholder:text-white focus:ring-0 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
          </fieldset>
          <p className="text-sm font-semibold leading-6 text-slate-900">
            Which service do you require?
          </p>
          <div className="space-y-4 mt-4">
            <div className="flex items-center">
              <input
                {...register("unscheduled")}
                type="radio"
                value="Empty or Exchange"
                name="unscheduled"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label className="ml-3 text-sm font-medium text-gray-900">
                Empty / Exchange
              </label>
            </div>
            <div className="flex items-center">
              <input
                {...register("unscheduled")}
                type="radio"
                value="Offhire or Replace"
                name="unscheduled"
                className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
              />
              <label className="ml-3 text-sm font-medium text-gray-900">
                Offhire / Remove
              </label>
            </div>
          </div>
          <fieldset>
            <div className="space-y-2">
              <textarea
                {...register("issue_message")}
                aria-invalid={errors["issue_message"] ? "true" : "false"}
                className="w-full px-3 py-2 rounded-md border bg-gray-100 text-sm placeholder-gray-500 focus:ring-0"
                placeholder="Further details (optional)"
              ></textarea>
              {errors.issue_message && (
                <div className="text-red-600">
                  {errors.issue_message.message}
                </div>
              )}
              <input
                type="file"
                name="file"
                id="file"
                {...register("file")}
                className="w-full px-3 py-2 rounded-md border bg-gray-100 text-sm placeholder-gray-500 focus:ring-0"
              />
            </div>
          </fieldset>
        </div>

        <div className="mt-6 flex justify-end">
          <button
            disabled={isSubmitting}
            type="submit"
            className="px-4 py-2 rounded-md bg-blue-500 text-white font-semibold text-sm shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

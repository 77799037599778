import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  BellIcon,
  XMarkIcon,
  UserCircleIcon,
  HomeIcon,
  EnvelopeIcon,
  CalendarDaysIcon,
  ClipboardDocumentListIcon,
} from "@heroicons/react/24/outline";
import { UserAuth } from "../../context/AuthContext";
import { useNavigate, Link, useLocation } from "react-router-dom";
import {
  ChartBarSquareIcon,
  ChatBubbleBottomCenterIcon,
  FlagIcon,
  MegaphoneIcon,
  TruckIcon,
} from "@heroicons/react/20/solid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function FooterNav() {
  const { user, logout } = UserAuth();
  const navigate = useNavigate;

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
      console.log("You are logged out");
    } catch (e) {
      console.log(e.message);
    }
  };

  const location = useLocation();

  const activeIconStyle = { backgroundColor: "#a0cdfa", color: "#3b82f6" }; // Use the desired color here

  return (
    <div className="flex justify-around items-end fixed bottom-0 w-full bg-gray-50 p-4">
      <Link to="/">
        <div className="flex flex-col items-center">
          <div
            className="w-10 h-10 flex items-center justify-center rounded-full text-gray-500"
            style={location.pathname === "/" ? activeIconStyle : {}}
          >
            <HomeIcon className="h-6 w-6 relative" aria-hidden="true" />
          </div>
          <span
            className="text-xs text-gray-500"
            style={location.pathname === "/" ? { color: "#3b82f6" } : {}}
          >
            Home
          </span>
        </div>
      </Link>
      <Link to="/schedule">
        <div className="flex flex-col items-center">
          <div
            className="w-10 h-10 flex items-center justify-center rounded-full text-gray-500"
            style={location.pathname === "/schedule" ? activeIconStyle : {}}
          >
            <CalendarDaysIcon className="h-6 w-6 relative" aria-hidden="true" />
          </div>
          <span
            className="text-xs text-gray-500"
            style={
              location.pathname === "/schedule" ? { color: "#3b82f6" } : {}
            }
          >
            Schedule
          </span>
        </div>
      </Link>
      <Link to="/issues">
        <div className="flex flex-col items-center">
          <div
            className="w-10 h-10 flex items-center justify-center rounded-full text-gray-500"
            style={location.pathname === "/issues" ? activeIconStyle : {}}
          >
            <ClipboardDocumentListIcon
              className="h-6 w-6 relative"
              aria-hidden="true"
            />
          </div>
          <span
            className="text-xs text-gray-500"
            style={location.pathname === "/issues" ? { color: "#3b82f6" } : {}}
          >
            Comms
          </span>
        </div>
      </Link>
    </div>
  );
}

// Import useState
import React, { useEffect, useState } from "react";
import { db } from "../firebase";
import {
  where,
  getDocs,
  collection,
  query,
  onSnapshot,
} from "firebase/firestore";
import { UserAuth } from "../context/AuthContext";
import { ClipboardDocumentListIcon } from "@heroicons/react/20/solid";
import CustomButton from "./CustomButton";
import CommsBottomSheet from "./layouts/CommsBottomSheet";

const BsIssues = () => {
  const { user } = UserAuth();
  const [appUserData, setAppUserData] = useState(null);
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState({
    equipmentName: "",
    facilityLocation: "",
    equipmentId: "",
  });
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);
  // Add state to store the selected reference number
  const [selectedReferenceNumber, setSelectedReferenceNumber] = useState(null);
  const [selectedIssueMessage, setSelectedIssueMessage] = useState(null);
  const [selectedSubmitDate, setSelectedSubmitDate] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        if (user) {
          const userQuery = query(
            collection(db, "AppUsers"),
            where("userId", "==", user.uid)
          );
          const userSnapshot = await getDocs(userQuery);
          if (!userSnapshot.empty) {
            const userDoc = userSnapshot.docs[0];
            const userDocData = userDoc.data();
            setAppUserData({
              name: userDocData.name,
              facility: userDocData.facility,
            });
            const userFacility = userDocData.facility;
            const issueQuery = query(
              collection(db, "Issue"),
              where("facility", "==", userFacility)
            );
            const issueSnapshot = await getDocs(issueQuery);
            const issueData = await Promise.all(
              issueSnapshot.docs.map(async (doc) => {
                const issueMessagesQuery = query(
                  collection(db, "Issue", doc.id, "Message")
                );
                const issueMessagesSnapshot = await getDocs(issueMessagesQuery);
                const issueMessagesData = issueMessagesSnapshot.docs.map(
                  (msgDoc) => ({
                    id: msgDoc.id,
                    ...msgDoc.data(),
                  })
                );
                const messageCollectionRef = collection(
                  db,
                  "Issue",
                  doc.id,
                  "Message"
                );
                const unsubscribe = onSnapshot(
                  messageCollectionRef,
                  (snapshot) => {
                    // Handle real-time updates if needed
                  }
                );

                return {
                  id: doc.id,
                  ...doc.data(),
                  messages: issueMessagesData,
                  unsubscribe,
                };
              })
            );
            setResults(issueData);
          }
        }
      } catch (error) {
        console.error("Error fetching issues:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    return () => {
      // Cleanup subscriptions if any
      // For example:
      // Object.values(results).forEach((result) => {
      //   if (result.unsubscribe) {
      //     result.unsubscribe();
      //   }
      // });
    };
  }, [user]);

  const groupDataByIssue = (data) => {
    const groupedData = {};
    data.forEach((result) => {
      const issueName = result.referenceNumber;
      if (!groupedData[issueName]) {
        groupedData[issueName] = [];
      }
      groupedData[issueName].push(result);
    });
    return groupedData;
  };

  const groupedResults = groupDataByIssue(results);

  const handleBottomSheetClose = () => {
    setIsBottomSheetOpen(false);
  };

  // Function to handle opening the BottomSheet and setting the selected reference number
  const handleOpenBottomSheet = (
    referenceNumber,
    issue_message,
    submitDate
  ) => {
    setIsBottomSheetOpen(true);
    setSelectedReferenceNumber(referenceNumber);
    setSelectedIssueMessage(issue_message);
    setSelectedSubmitDate(submitDate);
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <h2 className="text-base font-semibold leading-6 text-gray-900">
          Comms Reported
        </h2>
        <div className="w-10 h-10 flex items-center justify-center rounded-full bg-sky-700">
          <ClipboardDocumentListIcon className="h-5 w-5 text-white relative z-10" />
        </div>
      </div>
      <div className="h-full overflow-hidden py-4">
        <div className="h-full overflow-y-auto">
          <div className="mx-auto lg:mx-0 lg:max-w-none">
            {Object.keys(groupedResults).map((issueName, index) => {
              const filteredResults = groupedResults[issueName].filter(
                (result) => result.issue_status
              );
              if (filteredResults.length === 0) {
                return null;
              }
              return (
                <div key={index}>
                  <CustomButton
                    onClick={() =>
                      handleOpenBottomSheet(
                        issueName,
                        filteredResults[0]?.issue_message,
                        filteredResults[0].submitDate
                      )
                    }
                  >
                    <div className="flex items-center space-x-2">
                      <div
                        className={
                          filteredResults[0].issue_status
                            ? "w-6 h-6 rounded-full bg-teal-500"
                            : "w-6 h-6 rounded-full bg-red-500"
                        }
                      ></div>
                      <span className="text-xs font-bold text-slate-900">
                        {issueName}
                      </span>
                      <span className="text-xs text-gray-400">
                        {filteredResults[0].submitDate}
                      </span>
                    </div>
                    <div className="font-bold py-2 px-4 text-xs text-slate-900">
                      Issue reported: {filteredResults[0]?.issue_message}
                    </div>
                  </CustomButton>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* Render the BottomSheet component if isBottomSheetOpen is true */}
      {isBottomSheetOpen && (
        <CommsBottomSheet
          formType="issueReport"
          setOpen={handleBottomSheetClose}
          formData={formData}
          setFormData={setFormData}
          referenceNumber={selectedReferenceNumber} // Pass the selected reference number
          issue_message={selectedIssueMessage}
          submitDate={selectedSubmitDate}
        />
      )}
    </div>
  );
};

export default BsIssues;

import React, { Fragment, useEffect, useState } from "react";
import { db } from "../firebase";
import { where, getDocs, collection, query } from "firebase/firestore";
import { UserAuth } from "../context/AuthContext";
import { useLocation, Link } from "react-router-dom";

const Welcome = () => {
  const { user } = UserAuth();
  const [appUserData, setAppUserData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user) {
          const userQuery = query(
            collection(db, "AppUsers"),
            where("userId", "==", user.uid)
          );

          const userSnapshot = await getDocs(userQuery);

          if (!userSnapshot.empty) {
            const userDocData = userSnapshot.docs[0].data();
            setAppUserData({
              name: userDocData.name,
              facility: userDocData.facility,
            });
          }
        }
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    fetchData();
  }, [user]);

  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
      <div className="flex items-center rounded-lg bg-white px-5 py-6 shadow sm:px-6">
        {appUserData && (
          <div className="flex-shrink-0">
            <div className="bg-cyan-700 rounded-full h-8 w-8 text-white flex items-center justify-center text-sm font-bold">
              {appUserData.name.charAt(0).toUpperCase()}
            </div>
          </div>
        )}

        {appUserData && (
          <div className="ml-4">
            <div className="flex flex-col">
              <h1 className="font-bold tracking-tight text-slate-900">
                Hello {appUserData.name},
              </h1>
              <p className="text-xs text-slate-600">{appUserData.facility}</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Welcome;

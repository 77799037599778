import { motion, AnimatePresence } from "framer-motion";
import { useState, useRef, useEffect } from "react";
import { XMarkIcon } from "@heroicons/react/20/solid";
import IssueForm from "../IssueForm";
import { ChevronDoubleLeftIcon, XCircleIcon } from "@heroicons/react/24/solid";

export default function CommsBottomSheet({
  setOpen: parentSetOpen,
  formData,
  setFormData,
  formType,
  referenceNumber,
  issue_message,
  submitDate,
}) {
  const [open, setOpen] = useState(true);
  const bottomSheetRef = useRef(null);
  const [key, setKey] = useState(null);

  const handleClose = () => {
    setFormData({
      equipment_issue_message: "",
    });
    parentSetOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    setTimeout(() => {
      setKey(Date.now().toString());
    }, 100); // Adjust the delay based on your animation duration
  };

  useEffect(() => {
    if (open) {
      setKey(Date.now().toString());
    }
  }, [open]);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          handleClose();
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, handleClose]);
  };

  useOutsideAlerter(bottomSheetRef);

  useEffect(() => {
    console.log("Component re-rendered. Open:", open);
    // Additional logic or checks if needed
  }, [open]);

  return (
    <AnimatePresence initial={false}>
      {open && (
        <>
          <motion.div
            key="backdrop"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.1, zIndex: 3 }}
            exit={{ opacity: 0 }}
            className="fixed top-0 bottom-0 right-0 left-0 h-full w-screen bg-black"
          />
          <motion.div
            key={key}
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { y: 0, height: "auto" },
              collapsed: { y: "100%", height: 0 },
            }}
            transition={{ duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] }}
            className="fixed bottom-0 right-0 left-0 z-10 w-full border-2 border-b-0 border-gray-50 bg-white shadow-[0px_-8px_20px_-6px_rgba(0,0,0,0.1)]"
          >
            <div className="h-full">
              {/* Header content */}
              <div className="bg-sky-100 flex justify-between items-center mb-2 px-2 py-4 sticky top-0 z-20">
                <div className="flex items-center space-x-2">
                  <span className="text-xs font-bold text-slate-900">
                    {referenceNumber}
                  </span>
                  <span className="text-xs text-gray-400">{submitDate}</span>
                </div>
                <div className="font-bold py-2 px-4 text-xs text-slate-900">
                  Issue reported: {issue_message}
                </div>

                <div>
                  {/* Close icon */}
                  <XCircleIcon
                    className="w-6 cursor-pointer text-sky-400"
                    onClick={handleClose}
                  />
                </div>
              </div>

              {/* Scrollable content */}
              <div
                ref={bottomSheetRef}
                className="p-4 overflow-y-auto"
                style={{ maxHeight: "calc(100% - 80px)" }}
              >
                {formType === "issueReport" && (
                  <IssueForm
                    formData={formData}
                    setFormData={setFormData}
                    referenceNumber={referenceNumber}
                    issue_message={issue_message}
                    submitDate={submitDate}
                  />
                )}
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}

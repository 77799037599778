import { XMarkIcon } from "@heroicons/react/20/solid";
import { motion, AnimatePresence } from "framer-motion";
import { useState, useRef, useEffect } from "react";
import EquipmentReportForm from "../NewEquipmentForm";
//import AdhocReportForm from "../UnscheduledForm";
import ScheduleReportForm from "../ScheduleForm";
import UnscheduledReportForm from "../UnscheduledForm";
import IssueForm from "../IssueForm";

export default function BottomSheet({
  setOpen: parentSetOpen,
  formData,
  setFormData,
  formType,
  referenceNumber,
}) {
  const [open, setOpen] = useState(true);
  const bottomSheetRef = useRef(null);
  const [key, setKey] = useState(null);

  const handleClose = () => {
    setFormData({
      equipment_issue_message: "",
    });
    parentSetOpen(false);
  };

  const handleToggle = () => {
    console.log("handleToggle called");
    setOpen((prevOpen) => !prevOpen);

    setTimeout(() => {
      setKey(Date.now().toString());
    }, 100); // Adjust the delay based on your animation duration
  };
  useEffect(() => {
    if (open) {
      setKey(Date.now().toString());
    }
  }, [open]);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
          handleClose();
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, handleClose]);
  };

  useOutsideAlerter(bottomSheetRef);
  useEffect(() => {
    console.log("Component re-rendered. Open:", open);
    // Additional logic or checks if needed
  }, [open]);
  return (
    <AnimatePresence initial={false}>
      {open && (
        <>
          <motion.div
            key="backdrop"
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.1, zIndex: 3 }}
            exit={{ opacity: 0 }}
            className="fixed top-0 bottom-0 right-0 left-0 h-full w-screen bg-black"
          />
          <motion.div
            key={key}
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { y: 0, height: "auto" },
              collapsed: { y: "100%", height: 0 },
            }}
            transition={{ duration: 0.4, ease: [0.04, 0.62, 0.23, 0.98] }}
            className="fixed bottom-0 right-0 left-0 z-10 w-full rounded-t-3xl border-2 border-b-0 border-gray-50 bg-white shadow-[0px_-8px_20px_-6px_rgba(0,0,0,0.1)]"
          >
            <div ref={bottomSheetRef} className="p-4 overflow-y-auto">
              <div className="flex justify-between items-center mb-2">
                <div className="text-xs font-bold">{referenceNumber}</div>
                {/*                 <div>
                  <XMarkIcon className="w-6" onClick={handleToggle} />
                </div> */}
              </div>
              <div className="mb-2 flex justify-end">
                {/* <XMarkIcon className="w-6" onClick={handleToggle} /> */}
              </div>
              <div className="flex flex-col space-y-3">
                {formType === "equipmentReport" && (
                  <EquipmentReportForm
                    formData={formData}
                    setFormData={setFormData}
                    equipmentName={formData.equipmentName}
                    facilityLocation={formData.facilityLocation}
                    equipmentId={formData.equipmentId}
                  />
                )}
                {formType === "unscheduledReport" && (
                  <UnscheduledReportForm
                    formData={formData}
                    setFormData={setFormData}
                    container={formData.container}
                    facilityLocation={formData.facilityLocation}
                  />
                )}
                {formType === "scheduleReport" && (
                  <ScheduleReportForm
                    formData={formData}
                    setFormData={setFormData}
                    facilityLocation={formData.facilityLocation}
                    serviceName={formData.serviceName}
                    container={formData.container}
                  />
                )}
                {formType === "issueReport" && (
                  <IssueForm
                    formData={formData}
                    setFormData={setFormData}
                    referenceNumber={referenceNumber} // Pass the referenceNumber prop
                    // Add any additional props needed for IssueForm
                  />
                )}
              </div>
            </div>
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}

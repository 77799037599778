import React, { useEffect, useState } from "react";
import { BellIcon } from "@heroicons/react/24/outline";
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  doc,
  onSnapshot, // Import onSnapshot for real-time updates
} from "firebase/firestore";
import { db } from "../firebase"; // Assuming you have a file for Firebase initialization
import { UserAuth } from "../context/AuthContext";
import { Menu, Transition } from "@headlessui/react";
import Notification from "./layouts/Notification";

const TestNotifications = () => {
  const { user } = UserAuth();
  const [appUserData, setAppUserData] = useState(null);
  const [unreadMessages, setUnreadMessages] = useState(0);
  const [messages, setMessages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user) {
          const userQuery = query(
            collection(db, "AppUsers"),
            where("userId", "==", user.uid)
          );
          const userSnapshot = await getDocs(userQuery);

          if (!userSnapshot.empty) {
            const userDocData = userSnapshot.docs[0].data();
            setAppUserData({
              name: userDocData.name,
              facility: userDocData.facility,
            });
            const userFacility = userDocData.facility;

            const issueQuery = query(
              collection(db, "Issue"),
              where("facility", "==", userFacility),
              where("isRead", "==", false) // Fetch only unread messages
            );

            // Subscribe to real-time updates
            const unsubscribe = onSnapshot(issueQuery, (querySnapshot) => {
              const issueData = querySnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }));
              setMessages(issueData);
              setUnreadMessages(issueData.length); // Update unread message count
            });

            // Unsubscribe when component unmounts
            return () => unsubscribe();
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [user]);

  const handleNotificationClick = async () => {
    try {
      if (user) {
        setIsOpen(true); // Open the dropdown menu
      }
    } catch (error) {
      console.error("Error opening notifications:", error);
    }
  };

  const markAsRead = async (messageId) => {
    try {
      await updateDoc(doc(db, "Issue", messageId), { isRead: true });
      setUnreadMessages((prevUnreadMessages) => prevUnreadMessages - 1);
      console.log("Message marked as read:", messageId);
    } catch (error) {
      console.error("Error marking message as read:", error);
    }
  };

  return (
    <div className="relative">
      <Menu as="div">
        <div>
          <Menu.Button
            onClick={handleNotificationClick}
            className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
          >
            <span className="sr-only">View notifications</span>
            <BellIcon className="h-6 w-6" aria-hidden="true" />
            {unreadMessages > 0 && (
              <div className="px-1 bg-red-500 rounded-full text-center text-white text-xs absolute -top-1 -end-1">
                {unreadMessages}
              </div>
            )}
          </Menu.Button>
        </div>

        <Menu.Items
          style={{ display: isOpen ? "block" : "none" }} // Only show when open
        >
          <div>
            {messages.map((message) => (
              <Menu.Item key={message.id} className="my-2">
                <Notification
                  message={
                    message.issue_response
                      ? message.issue_response
                      : "We've recieved your support request and will get back to you ASAP" // Conditionally render the message
                  }
                  referenceNumber={message.referenceNumber}
                  onClose={() => markAsRead(message.id)}
                />
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Menu>
    </div>
  );
};

export default TestNotifications;

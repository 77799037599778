import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { db } from "../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  addDoc,
  onSnapshot,
  serverTimestamp,
} from "firebase/firestore";
import { UserAuth } from "../context/AuthContext";
import { PaperAirplaneIcon } from "@heroicons/react/20/solid";

const NewMessages = () => {
  const { user } = UserAuth();
  const [loading, setLoading] = useState(true); // Initialize loading state
  const [appUserData, setAppUserData] = useState(null);
  const [messages, setMessages] = useState({});
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = async (data) => {
    try {
      // Add the new message to the collection
      const messageCollectionRef = collection(
        db,
        "Issue",
        "vQomM9d1pEOzflLepp9d",
        "Message"
      );
      await addDoc(messageCollectionRef, {
        message: data.message,
        utype: true, // Assuming this is a default value
        timestamp: serverTimestamp(),
      });
      reset();
      console.log("Message sent successfully.");
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (user) {
          const userQuery = query(
            collection(db, "AppUsers"),
            where("userId", "==", user.uid)
          );
          const userSnapshot = await getDocs(userQuery);

          if (!userSnapshot.empty) {
            const userDocData = userSnapshot.docs[0].data();
            setAppUserData({
              name: userDocData.name,
              facility: userDocData.facility,
            });
            const userFacility = userDocData.facility;

            const issueQuery = query(
              collection(db, "Issue"),
              where("facility", "==", userFacility)
            );
            const issueSnapshot = await getDocs(issueQuery);

            const messagesData = {};

            for (const issueDoc of issueSnapshot.docs) {
              const issueData = issueDoc.data();
              const issueId = issueDoc.id;
              const submitDate = issueData.submitDate;
              const referenceNumber = issueData.referenceNumber;
              console.log("Issue Data:", issueData);
              console.log("Submit Date:", submitDate); // Add this line
              console.log("ref no:", referenceNumber);
              const messageCollectionRef = collection(
                db,
                "Issue",
                issueId,
                "Message"
              );

              // Subscribe to real-time updates for messages
              const unsubscribe = onSnapshot(
                messageCollectionRef,
                (snapshot) => {
                  const newMessages = snapshot.docs.map((doc) => ({
                    messageId: doc.id,
                    ...doc.data(),
                  }));
                  // Update messages state with the new messages
                  setMessages((prevMessages) => ({
                    ...prevMessages,
                    [issueId]: newMessages,
                  }));
                }
              );

              // Store the unsubscribe function for cleanup
              messagesData[issueId] = {
                unsubscribe,
                submitDate,
                referenceNumber,
              };
            }

            console.log("All messages:", messagesData);
            setLoading(false); // Set loading to false when data is fetched
          }
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false); // Set loading to false if there's an error
      }
    };

    fetchData();

    // Cleanup function to unsubscribe from listeners
    return () => {
      Object.values(messages).forEach((message) => {
        if (message.unsubscribe) {
          message.unsubscribe();
        }
      });
    };
  }, [user]);

  if (loading) {
    return <div>Loading...</div>; // Render loading indicator
  }

  return (
    <div>
      {/* New message form */}
      <div>
        <h2>New Message</h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            type="text"
            placeholder="Write your message"
            {...register("message", { required: true })}
            className="w-full focus:outline-none focus:placeholder-gray-400 text-gray-600 placeholder-gray-600 pl-2 bg-gray-200 rounded-md py-3"
          />
          <button
            className="inline-flex items-center gap-x-1.5 rounded-md bg-sky-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            type="submit"
          >
            <span className="font-bold">Send</span>
            <PaperAirplaneIcon className="-mr-0.5 h-5 w-5" aria-hidden="true" />
          </button>
        </form>
      </div>

      <h2>All Messages</h2>

      <div className="h-full overflow-hidden py-4">
        <div className="h-full overflow-y-auto">
          <div className="mx-auto lg:mx-0 lg:max-w-none">
            {/* Render messages for each issue */}
            {Object.keys(messages).map((issueId) => (
              <div key={issueId}>
                {/* Render issue header */}
                <div className="border-b border-gray-200 mb-4 pb-4">
                  <h3 className="text-lg font-semibold text-gray-900">
                    {console.log(
                      "Reference Number:",
                      messages[issueId].referenceNumber
                    )}
                    Issue: {messages[issueId].submitDate}{" "}
                    {/* Display the submitDate */}
                  </h3>
                  {/* You can also include the submit date if needed */}
                </div>
                {/* Render messages for the issue in reverse chronological order */}
                {messages[issueId]
                  .slice() // Create a shallow copy of the array to avoid mutating the original
                  .sort(
                    (a, b) =>
                      (b.timestamp?.seconds || 0) * 1000 -
                      (a.timestamp?.seconds || 0) * 1000 // Compare timestamps in reverse
                  )
                  .map((message) => (
                    <div key={message.messageId}>
                      {message.utype ? (
                        // If utype is true
                        <>
                          <div className="flex pt-4 items-center">
                            {appUserData && (
                              <div className="bg-cyan-700 h-8 w-8 rounded-full flex-shrink-0-full text-white flex items-center justify-center text-sm font-bold">
                                {appUserData.name.charAt(0).toUpperCase()}
                              </div>
                            )}
                            <div className="ml-3 bg-white py-2 px-4 shadow rounded-xl text-xs text-slate-600">
                              {message.message}
                            </div>
                          </div>
                          <div className="text-right mt-4 text-xs text-slate-400">
                            {message.timestamp &&
                              new Date(
                                message.timestamp.seconds * 1000
                              ).toLocaleString()}{" "}
                            {/* Convert timestamp to date */}
                          </div>
                        </>
                      ) : (
                        // If utype is false
                        <div className="mt-2 flex items-center justify-start flex-row-reverse">
                          <img
                            src={`${process.env.PUBLIC_URL}/logo192.png`}
                            alt="Company Logo"
                            className="h-8 w-8"
                          />
                          <div className="mr-3 text-sm bg-indigo-100 py-2 px-4 shadow rounded-xl">
                            <div className="text-xs leading-5 text-slate-600">
                              {message.message}
                            </div>
                          </div>
                          <div className="mr-16 text-xs text-slate-400">
                            {message.timestamp &&
                              new Date(
                                message.timestamp.seconds * 1000
                              ).toLocaleString()}{" "}
                            {/* Convert timestamp to date */}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewMessages;

import React from "react";
import FullWidthButton from "./layouts/FullWidthButton";
import {
  CalendarDaysIcon,
  CalendarIcon,
  FlagIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { ClockIcon } from "@heroicons/react/24/solid";

const Home = () => {
  return (
    <div>
      <Link to="/schedule">
        <FullWidthButton
          title="Scheduled Services"
          subtitle="View schedule and report issues"
          icon={CalendarDaysIcon}
          gradient="bg-gradient-to-r from-sky-600 to-sky-700"
        />
      </Link>
      <Link to="/unscheduled">
        <FullWidthButton
          title="Unscheduled Services"
          subtitle="Request an unscheduled collection"
          icon={ClockIcon}
          gradient="bg-gradient-to-r from-teal-500 to-teal-500"
        />
      </Link>
      <Link to="/adhoc">
        <FullWidthButton
          title="Adhoc Services"
          subtitle="View adhoc services and report issues"
          icon={CalendarIcon}
          gradient="bg-gradient-to-r from-yellow-400 to-yellow-500"
        />
      </Link>
      <Link to="/equipment">
        <FullWidthButton
          title="Equipment"
          subtitle="View equipment and report issues"
          icon={WrenchScrewdriverIcon}
          gradient="bg-gradient-to-r from-cyan-700 to-cyan-800"
        />
      </Link>
      {/* You can use the component with different gradients if needed */}
      <Link to="non-conformance">
        <FullWidthButton
          title="Non Conformance"
          subtitle="Report waste conformance issues"
          icon={FlagIcon}
          gradient="bg-gradient-to-r from-red-400 to-red-600"
        />
      </Link>
    </div>
  );
};

export default Home;

import React, { useState } from "react";
import { Transition } from "@headlessui/react";
import { Fragment } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

const Notification = ({ message, referenceNumber, onClose }) => {
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
    onClose(); // Mark message as read
  };

  return (
    <div>
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex px-4 py-6 sm:items-start sm:p-6"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end items-start">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}
          <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="pointer-events-auto mt-8 w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
              <div className="p-4">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <img
                      src={`${process.env.PUBLIC_URL}/logo192.png`}
                      alt="Company Logo"
                      className="w-6 h-6 mr-3"
                    />
                  </div>
                  <div className="ml-3 w-0 flex-1 pt-0.5">
                    <h3 className="font-bold text-base text-gray-800">
                      {referenceNumber}
                    </h3>

                    {/* <p className="text-xs text-gray-500">2 min ago</p> */}

                    <p className="mt-1 text-sm">{message}</p>
                  </div>

                  <div className="ml-4 flex flex-shrink-0">
                    <button
                      type="button"
                      onClick={handleClose}
                      className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Transition>
        </div>
      </div>
    </div>
  );
};

export default Notification;

import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Outlet } from "react-router-dom";
import {
  Bars3Icon,
  BellIcon,
  XMarkIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { UserAuth } from "../../context/AuthContext";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import FooterNav from "./FooterNav";
import Welcome from "../Welcome";
import TestNotifications from "../TestNotifications";
import MessageNotifications from "../MessageNotifications";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AuthLayout() {
  const { user, logout } = UserAuth();
  const navigate = useNavigate;
  const location = useLocation();

  const navigation = [
    { name: "Dashboard", href: "/", current: location.pathname === "/" },
    {
      name: "Unscheduled Services",
      href: "/unscheduled",
      current: location.pathname === "/unscheduled",
    },
    {
      name: "Equipment",
      href: "/equipment",
      current: location.pathname === "/equipment",
    },
    {
      name: "Schedule",
      href: "/schedule",
      current: location.pathname === "/schedule",
    },
    {
      name: "Non Conformance",
      href: "/non-conformance",
      current: location.pathname === "/non-conformance",
    },
  ];

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
      console.log("You are logged out");
    } catch (e) {
      console.log(e.message);
    }
  };
  //  const backgroundImageUrl = `${process.env.PUBLIC_URL}/44.jpg`; // Update the path accordingly

  return (
    <>
      <div className="min-h-full">
        <div
          className="bg-gradient-to-r from-cyan-700 to-teal-500 pb-32"
          /*           style={{
            backgroundImage: `url(${backgroundImageUrl})`,
            backgroundSize: "cover",
          }} */
        >
          <Disclosure as="nav" className="bg-white shadow">
            {({ open }) => (
              <>
                <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                  <div className="flex h-16 justify-between">
                    <div className="flex">
                      <div className="flex flex-shrink-0 items-center">
                        <img
                          className="block h-8 w-auto lg:hidden"
                          src="https://futurfirst.co.uk/wp-content/uploads/2019/09/cropped-FFLogo.png"
                          alt="FuturFirst"
                        />
                        <img
                          className="hidden h-8 w-auto lg:block"
                          src="https://futurfirst.co.uk/wp-content/uploads/2019/09/cropped-FFLogo.png"
                          alt="FuturFirst"
                        />
                      </div>
                      <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                        {navigation.map((item) => (
                          <NavLink
                            key={item.name}
                            to={item.href} // Use the relative path
                            className={classNames(
                              item.current
                                ? "border-fforange text-gray-900"
                                : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                              "inline-flex items-center border-b-2 px-1 pt-1 text-sm font-medium"
                            )}
                            aria-current={item.current ? "page" : undefined}
                          >
                            {item.name}
                          </NavLink>
                        ))}
                      </div>
                    </div>
                    <div className="ml-6 flex items-center">
                      {/*                       <button
                        type="button"
                        className="rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                      >
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button> */}
                      <MessageNotifications />
                      <TestNotifications />

                      {/* Profile dropdown */}
                      <Menu as="div" className="relative ml-3">
                        <div>
                          <Menu.Button className="flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2">
                            <span className="sr-only">Open user menu</span>
                            <UserCircleIcon
                              className="h-6 w-6"
                              aria-hidden="true"
                            />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <Menu.Item>
                              {({ active }) => (
                                <p
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  {user && user.email}
                                </p>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <NavLink
                                  to="/faqs"
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  Help
                                </NavLink>
                              )}
                            </Menu.Item>
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  onClick={handleLogout}
                                  href="/"
                                  className={classNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm text-gray-700"
                                  )}
                                >
                                  Sign out
                                </a>
                              )}
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                    <div className="hidden -mr-2 flex items-center sm:hidden">
                      {/* Mobile menu button */}
                      <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary-500">
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XMarkIcon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        ) : (
                          <Bars3Icon
                            className="block h-6 w-6"
                            aria-hidden="true"
                          />
                        )}
                      </Disclosure.Button>
                    </div>
                  </div>
                </div>

                <Disclosure.Panel className="sm:hidden">
                  <div className="space-y-1 pb-3 pt-2">
                    {navigation.map((item) => (
                      <Disclosure.Button key={item.name} as="div">
                        <NavLink
                          to={item.href}
                          className={classNames(
                            item.current
                              ? "border-yellow-500 bg-primary-50 text-primary-700"
                              : "border-transparent text-gray-600 hover:border-gray-300 hover:bg-gray-50 hover:text-gray-800",
                            "block border-l-4 py-2 pl-3 pr-4 text-base font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </NavLink>
                      </Disclosure.Button>
                    ))}
                  </div>
                  <div className="border-t border-gray-200 pb-3 pt-4">
                    <div className="flex items-center px-4">
                      <div className="flex-shrink-0">
                        <UserCircleIcon
                          className="h-6 w-6"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="ml-3">
                        <div className="text-base font-medium text-gray-800"></div>
                        <div className="text-sm font-medium text-gray-500">
                          {user && user.email}
                        </div>
                      </div>
                      <button
                        type="button"
                        className="ml-auto flex-shrink-0 rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                      >
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                    </div>
                    <div className="mt-3 space-y-1">
                      <Disclosure.Button
                        as="a"
                        href="/"
                        onClick={handleLogout}
                        className="block px-4 py-2 text-base font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-800"
                      >
                        Sign out
                      </Disclosure.Button>
                    </div>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <header className="py-10">
            <Welcome />
          </header>
        </div>

        {/* <FacilityInfo /> */}
        {/*         <main>
          <div className="relative mx-auto mt-2 w-full max-w-container sm:px-6 lg:px-8">
            <div className="space-y-24">
              <div className="mx-auto max-w-lg px-6 py-8 bg-white lg:max-w-4xl xl:max-w-6xl rounded-t-3xl ring-1 ring-slate-900/10">
                <Outlet />
              </div>
            </div>
          </div>
        </main> */}
        <main className="-mt-32">
          <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
            <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
              <Outlet />
            </div>
          </div>
        </main>
      </div>
      <FooterNav />
    </>
  );
}

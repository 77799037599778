import * as React from "react";
import { useForm } from "react-hook-form";
import isEmpty from "lodash/isEmpty";
import { useState, useEffect } from "react";
import { db, storage } from "../firebase";
import {
  getDocs,
  collection,
  addDoc,
  query,
  where,
  serverTimestamp,
} from "firebase/firestore";
import { UserAuth } from "../context/AuthContext";
import { useNavigate, useLocation } from "react-router-dom";
//import RadioAlert from "./layouts/RadioAlert";
//import { FacilityContext } from "../context/FacilityContext";
import { showAlert } from "tailwind-toastify";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { toast, Toaster } from "react-hot-toast";

export default function EquipmentReportForm({
  formData,
  setFormData,
  equipmentName,
  facilityLocation,
  equipmentId,
}) {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm();
  // eslint-disable-next-line
  const [successMsg, setSuccessMsg] = useState("");
  // eslint-disable-next-line
  const [facility, setFacility] = useState(""); // Assuming 'facility' is a string, adjust the type accordingly
  // eslint-disable-next-line
  const [name, setName] = useState("");

  const { user } = UserAuth();
  const uid = user ? user.uid : null; // Check if user exists before accessing the UID
  const navigate = useNavigate();
  //const { facility } = useContext(FacilityContext);

  const submitDate = new Date().toLocaleString("en-GB");

  //console.log("Facility value:", facility);

  const location = useLocation();

  useEffect(() => {
    // Function to fetch data from AppUsers collection
    const fetchUserData = async () => {
      try {
        const appUsersQuerySnapshot = await query(
          collection(db, "AppUsers"),
          where("userId", "==", uid)
        );
        const appUsersDocs = await getDocs(appUsersQuerySnapshot);

        if (!isEmpty(appUsersDocs.docs)) {
          const userData = appUsersDocs.docs[0].data();
          const { name, facility } = userData;

          // Set the fetched data in the form using setValue
          // eslint-disable-next-line
          setValue("name", name);
          // eslint-disable-next-line
          setValue("facility", facility);
          setValue("equipmentName", equipmentName);
          setValue("facilityLocation", facilityLocation);
          setValue("equipmentId", equipmentId);
        } else {
          // Handle the case when no document is found
          console.error("User data not found");
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    // Call the function to fetch user data
    if (uid) {
      fetchUserData();
    }
  }, [uid, setValue, equipmentId, equipmentName, facilityLocation]);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, [location]);

  const [isOnline, setIsOnline] = useState(true);

  useEffect(() => {
    function handleOffline() {
      setIsOnline(false);
    }

    function handleOnline() {
      setIsOnline(true);
    }

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    return () => {
      window.removeEventListener("offline", handleOffline);
      window.removeEventListener("online", handleOnline);
    };
  }, []);

  const onSubmit = async (formData) => {
    try {
      // Generate referenceNumber asynchronously
      const referenceNumber = await generateReferenceNumber(
        formData.issue_type
      );

      if (!referenceNumber) {
        // Handle the case where referenceNumber could not be generated
        console.error("Error generating reference number");
        return;
      }

      let fileMetadata = null; // Initialize fileMetadata as null

      if (formData.file && formData.file.length > 0) {
        const file = formData.file[0];
        // Create a reference to the storage location
        const storageRef = ref(storage, `images/equipment/${file.name}`);

        // Upload the file to storage
        await uploadBytes(storageRef, file);

        // Get the file URL
        const fileUrl = await getDownloadURL(storageRef);

        fileMetadata = {
          name: file.name,
          type: file.type,
          size: file.size,
          url: fileUrl,
        };
      }

      // Add the form data to the Issue collection
      await addDoc(collection(db, "Issue"), {
        submitDate: submitDate,
        name: formData.name,
        facility: formData.facility,
        uid: formData.uid,
        issue_message: formData.issue_message,
        issue_type: formData.issue_type,
        issue_status: formData.issue_status,
        referenceNumber: referenceNumber,
        equipmentName: formData.equipmentName,
        facilityLocation: formData.facilityLocation,
        equipmentId: formData.equipmentId,
        isRead: false, // Set isRead to false by default
        file: fileMetadata,
      });

      // Add the new message to the Message sub-collection
      /*     const messageCollectionRef = collection(db, "Issue", referenceNumber, "Message");
    await addDoc(messageCollectionRef, {
      message: formData.message,
      utype: true, // Assuming this is a default value
      timestamp: serverTimestamp(),
    }); */

      /*       showAlert(
        "success",
        "We're on it!",
        `We've received your support request and will get back to you ASAP. Reference Number: ${referenceNumber}`
      ); */

      toast.success(
        `We've received your support request and will get back to you ASAP. Reference Number: ${referenceNumber}`,
        {
          duration: 4000, // Duration in milliseconds
        }
      );

      console.log("Form submitted", formData);
      reset();
      if (isOnline) {
        // Perform form submission logic for online mode
        console.log("Form submitted online");
        navigate("/");
      } else {
        // Perform form submission logic for offline mode
        console.log("Form submitted offline");
        navigate("/");
      }
    } catch (error) {
      console.error("Error uploading file to Firebase Storage:", error);
    }
  };

  const generateReferenceNumber = async (issueType) => {
    // You can customize this logic based on your requirements
    const prefix = issueType === "Equipment" ? "EQ" : "CO";
    const randomDigits = Math.floor(10000 + Math.random() * 90000);
    return `${prefix}${randomDigits}`;
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          {...register("facility")}
          type="hidden"
          value=""
          name="facility"
        />
        <input type="hidden" {...register("issue_status")} value="true" />

        <input {...register("uid")} type="hidden" value={uid} name="uid" />
        {/* Add additional hidden fields for data from AppUsers */}
        <input {...register("name")} type="hidden" value="" name="name" />
        <input
          {...register("issue_type")}
          type="hidden"
          value="Equipment"
          name="issue_type"
        />
        <div className="mt-0">
          {successMsg && <p className="success-msg">{successMsg}</p>}

          <div className="pb-1 pt-1 rounded-lg bg-white px-5 py-6 sm:px-6">
            {errors.root?.serverError && (
              <p>Something went wrong, and please try again.</p>
            )}
            <div className="w-full mt-4 mb-4">
              <h1 className="text-center rounded-md text-2xl font-semibold leading-7">
                Request a service
              </h1>
            </div>
            <p className="mt-1 text-center text-sm leading-6 text-gray-600">
              Please Use this form to request an adhoc service. We'll get back
              to you ASAP.
            </p>
          </div>

          <div className="mt-4 pb-1 pt-1 rounded-lg bg-white px-5 py-6 sm:px-6">
            <div className="space-y-10">
              <fieldset disabled="true">
                <div className="sm:col-span-4">
                  <div className="mt-2">
                    <div className="text-xs flex rounded-md shadow-sm focus-within:ring-2 focus-within:ring-inset focus-within:ring-slate-300 sm:max-w-md">
                      <input
                        {...register("equipmentName")}
                        type="text"
                        value={equipmentName}
                        className="block flex-1 rounded-lg border-0 bg-slate-100 py-1.5 pl-1 text-slate-600 placeholder:text-white focus:ring-0 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
                <div className="sm:col-span-4">
                  <div className="mt-2">
                    <div className="text-xs flex rounded-md shadow-sm focus-within:ring-2 focus-within:ring-inset focus-within:ring-slate-300 sm:max-w-md">
                      <input
                        {...register("facilityLocation")}
                        type="text"
                        value={facilityLocation}
                        className="block flex-1 rounded-lg border-0 bg-slate-100 py-1.5 pl-1 text-slate-600 placeholder:text-white focus:ring-0 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
                <div className="sm:col-span-4">
                  <div className="mt-2">
                    <div className="text-xs flex rounded-md shadow-sm focus-within:ring-2 focus-within:ring-inset focus-within:ring-slate-300 sm:max-w-md">
                      <input
                        {...register("equipmentId")}
                        type="text"
                        value=""
                        className="block flex-1 rounded-lg border-0 bg-slate-100 py-1.5 pl-1 text-slate-600 placeholder:text-white focus:ring-0 sm:text-sm sm:leading-6"
                      />
                    </div>
                  </div>
                </div>
              </fieldset>
              <fieldset>
                <legend className="text-sm font-semibold leading-6 text-gray-900">
                  Please explain the issue with the equipment
                </legend>
                <div className="mt-4 space-y-6">
                  <label className="block text-sm font-medium leading-6 text-gray-900">
                    <div className="flex items-center gap-x-3">
                      <textarea
                        {...register("issue_message", {
                          required: "Please enter an explanation",
                        })}
                        aria-invalid={
                          errors["issue_message"] ? "true" : "false"
                        }
                        className="form-textarea ring-1 ring-inset ring-teal-500 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 mt-1 block w-full p-2 border rounded-md"
                      />
                    </div>
                  </label>
                  <input
                    type="file"
                    name="file"
                    id="file"
                    {...register("file")}
                    className="w-full p-2 border rounded-md"
                  />
                  {errors.issue_message && (
                    <div className="text-red-600">
                      {errors.issue_message.message}
                    </div>
                  )}
                </div>
              </fieldset>
            </div>
          </div>
          <div className="pb-1 pt-1 rounded-lg bg-white px-5 py-6 sm:px-6 mt-2 flex items-center justify-end gap-x-6">
            <button
              disabled={isSubmitting}
              type="submit"
              className="rounded-md bg-cyan-100 px-3 py-2 text-sm font-semibold text-cyan-700 shadow-sm hover:bg-cyan-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600"
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

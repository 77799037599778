import React from "react";
import { InformationCircleIcon } from "@heroicons/react/20/solid";

export default function Alert({ message }) {
  return (
    <div className="rounded-md bg-purple-100 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon
            className="h-5 w-5 text-purple-800"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <p className="text-sm font-medium text-purple-800">{message}</p>
        </div>
      </div>
    </div>
  );
}
